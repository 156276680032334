.accordion-body {
  padding-bottom: 0px;
  background-color: rgba(255, 255, 255, 0);

  border-radius: 10px; /* 5px rounded corners */
}

.collapse,
.collapsing {
  height: fit-content;
  padding-bottom: 0px;
}

#accordian {
  text-align: left;
}

.accordion-button {
  text-align: center;
  color: rgb(96, 161, 36);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
}
.accordion-button:after {
  background-color: rgba(255, 255, 255, 0);
}

.accordion-button:hover {
  background-color: rgb(96, 161, 36) !important;
  color: rgb(38, 36, 161);
}
.accordion-item {
  background-color: rgba(38, 36, 161, 0);
}

.collapsed {
  border-radius: 10px; /* 5px rounded corners */
  background-color: rgba(255, 255, 255, 0);

  text-align: center;
}
/*accordion-button collapsed //*/

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button.collapsed {
  text-align: center;
}

.accordion-header {
  background-color: rgba(255, 255, 255, 0);
}

.accordion-item:first-of-type .accordion-button {
  background-color: rgba(255, 255, 255, 0);
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.856);
  color: rgb(38, 36, 161);
}
.nav-link {
  color: rgba(255, 255, 255, 0.856);
}

.nav-link.active {
  color: rgba(255, 255, 255, 0.856) !important;
  background-color: rgb(38, 36, 161) !important;
}
