.radar-container {
}
.radar-can {
}

canvas {
  /* background-color: white;*/
  height: 300px;
  z-index: 1;
}
