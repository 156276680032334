.tsparticles-canvas-el {
  z-index: 0 !important;
}
.header {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  padding-top: 2%;
  padding-bottom: 2%;
  color: white;
}
.superinvestors-btn{
  z-index: 1000;
  display: inline;
    color: yellow;
    border-style: solid;
    width: fit-content;
    margin: 0 auto;
    border-radius: 10px;
    padding: 10px;
    border-width: 1px;
    box-shadow: 0 0 5px;
    background: #ffff000f;
      transition: 0.2s;
}
.superinvestors-btn:hover {
  transition:0.2s;
cursor: pointer;
  background: #ffff002d;
}
.MuiInputLabel-outlined.MuiInputLabel-marginDense{
transform:translate(17px, 8px) scale(1) !important
}
.statements-container {
  position: relative;
  width: 100%;
  height: 400px;

  transition: 0.3s;

  border-radius: 5px;
  color: rgb(204, 229, 233);

  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
      opacity: 0;
        transform: scale(85%);
        transition: 1.15s;
}
.statements-container--zoom{
  position: relative;
    width: 100%;
    height: 400px;
  
    transition: 0.3s;
  
    border-radius: 5px;
    color: rgb(204, 229, 233);
  
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
        opacity: 1;
          transform: scale(100%);
          transition: 1.15s;
}
.statements-img {
  position: absolute;

  top: 35px;
  width: 115px;
  height: 115px;
  left: calc(50% - 63px);
}

.calculator-container {
  position: relative;
  width: 100%;
  height: 325px;
  transition: 0.3s;

  border-radius: 10px; /* 5px rounded corners */
  color: rgb(204, 229, 233);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  padding-top: 15px;
          opacity: 0;
            transform: scale(85%);
            transition: 1.15s;
}
.calculator-container--zoom {
  position: relative;
  width: 100%;
  height: 325px;
  transition: 0.3s;

  border-radius: 10px; /* 5px rounded corners */
  color: rgb(204, 229, 233);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  padding-top: 15px;
        opacity: 1;
          transform: scale(100%);
          transition: 1.15s;
}

.calculator-img {
  position: absolute;

  top: 35px;
  width: 115px;
  height: 115px;
  left: calc(50% - 63px);
}

.alerts-container {
  position: relative;
  width: 100%;
  height: 400px;
  transition: 0.3s;
  border-radius: 10px; /* 5px rounded corners */
  color: rgb(204, 229, 233);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
    opacity: 0;
      transform: scale(85%);
        transition: 1.15s;
}
.alerts-container--zoom {
  position: relative;
  width: 100%;
  height: 400px;
  transition: 0.3s;
  border-radius: 10px; /* 5px rounded corners */
  color: rgb(204, 229, 233);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
    opacity: 1;
      transform: scale(100%);
        transition: 1.15s;
}
.alerts-img {
  position: absolute;

  top: 35px;
  width: 115px;
  height: 115px;
  left: calc(50% - 63px);
}

.offering-description {
  z-index: 9;

  position: relative;
  top: 160px;
  color: rgb(201, 198, 25);
  font-size: 22px;
}

/*large screen*/
@media only screen and (min-width: 600px) {
  .tiktok-image {
    filter: grayscale(0%) opacity(0.8);

    z-index: 100;
    position: absolute;

    /* margin-top: 19%;
50% */
    /* top: 35px; */
    width: 80px;
    height: 80px;
    left: calc(50% - 63px);
    margin-top: calc(34.5% - 40px);
  }
  .tiktok-image:hover {
    transition: 0.7s;
    filter: grayscale(0%) opacity(1);
  }
  .followers {
    position: absolute;

    z-index: 100;
    color: white;
    background-color: rgba(0, 0, 0, 0.712);
    border-radius: 5px;
    left: calc(50% + 30px);
    padding: 10px;
    /* padding-left: 20px;
  padding-right: 20px; */

    margin-top: calc(34.5% - 20px);
  }
  .followers:hover {
    transition: 0.7s;
    background-color: rgb(0, 0, 0);
  }
  .lock-img {
    top: 35px;
    width: 200px;
    height: 200px;
    margin-left: calc(50% - 100px);
  }
  #tsparticles {
    z-index: 1;
    left: 5%;
    background-color: rgba(255, 228, 196, 0);
    position: fixed;
    height: 100%;
    width: 95%;
  }
  .start-h3 {
    z-index: 9;
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    right: 0;
    margin-top: 35px;
  }
  .services-button {
    background-color: rgba(216, 80, 17, 0.342);

    border-style: solid;
    border-color: rgb(216, 80, 17);
    color: white;
    text-decoration: none;
    border-width: 1px;
    font-size: 100%;
    font-weight: lighter;

    width: 15%;

    margin-left: 42.5%;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 7px;

    text-align: center;
    z-index: 2;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }

  .services-button:hover {
    background-color: rgb(216, 80, 17);

    border-style: solid;
    border-color: rgb(216, 80, 17);
    color: white;
    text-decoration: none;
    border-width: 1px;
    font-size: 100%;
    font-weight: lighter;

    width: 15%;
    margin-left: 42.5%;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 7px;

    text-align: center;
    z-index: 2;
  }

  .services-offering {
    padding-left: 5%;
    display: inline;
    text-align: right;
    color: white;
    font-size: 20px;
    margin-right: 0;
    margin-top: 0%;
    padding-top: 0%;
    word-wrap: break-word;
  }
  #save {
    margin-top: 5%;
  }

  #cloud {
    background-color: blue;
    padding-right: 40%;
    margin-right: 25%;
  }
  .api-img {
  }

  .explore-research-button {
    width: 15%;
    left: 10%;
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(17, 216, 17, 0.342);
    border-color: rgb(17, 216, 17);
    z-index: 9;
    top: 0px;
    position: absolute;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }

  .explore-research-button:hover {
    width: 15%;
    left: 10%;

    background-color: rgb(17, 216, 17);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(17, 216, 17);
    z-index: 9;
    top: 0px;
    position: absolute;
  }
  .explore-services-button {
    width: 15%;
    left: 42.5%;

    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(216, 80, 17, 0.342);
    border-color: rgb(216, 80, 17);
    z-index: 9;
    top: 0px;
    position: absolute;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }
  .explore-services-button:hover {
    width: 15%;
    left: 42.5%;

    background-color: rgb(216, 80, 17);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(216, 80, 17);
    z-index: 9;
    top: 0px;
    position: absolute;
  }

  .explore-blog-button {
    width: 15%;
    right: 10%;

    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(19, 19, 221, 0.349);

    border-color: rgb(19, 19, 221);
    z-index: 9;
    top: 0px;
    position: absolute;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }
  .explore-blog-button:hover {
    width: 15%;
    right: 10%;

    background-color: rgb(19, 19, 221);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(19, 19, 221);
    z-index: 9;
    top: 0px;
    position: absolute;
  }

  .backdropDiv {
    position: relative;
    height: fit-content;
    width: 100%;
  }

  .fixed-tickerEnter {
    width: 100%;
    position: fixed;
    z-index: 2;
    margin-top: 0%;
    margin-left: 0;
  }
}

.cloudDiv {
  /*background-color: orange;*/
  z-index: 9;
        opacity: 0;
          transform: scale(85%);
          transition: 1.15s;
}

.cloudDiv--zoom {
  /*background-color: orange;*/
  z-index: 9;
        opacity: 1;
          transform: scale(100%);
          transition: 1.15s;
}

.superinvestorsPreview-img{
  width: 200px;
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .row {
    margin-right: 0% !important;
  }
  .superinvestorHoldings-img{
        width: 100%;
  }
}

/*small screen*/
@media only screen and (max-width: 600px) {
  .superinvestorHoldings-img{
    width: 100vw;
  }
  .tiktok-image {
    z-index: 100;
    position: absolute;

    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    margin-top: calc(34.5% - 20px);
  }
  .followers {
    position: absolute;

    z-index: 100;
    color: white;
    background-color: black;
    border-radius: 5px;
    padding: 2px;
    font-size: 10px;
    left: calc(50% - 12.5px);

    margin-top: calc(34.5% + 22px);
  }

  #mobileTT {
    background-color: orange !important;
  }

  .lock-img {
    top: 35px;
    width: 110px;
    height: 110px;
    margin-left: calc(50% - 55px);
  }
  #tsparticles {
    z-index: 1;

    background-color: rgba(255, 228, 196, 0);
    position: fixed;
    height: 100%;
    width: 100%;
  }
  .start-h3 {
    z-index: 9;
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    right: 0;
    margin-top: 35px;
  }
  .services-button {
    width: 25%;

    background-color: rgba(216, 80, 17, 0.342);
    font-size: 0.75rem !important;

    border-style: solid;
    border-color: rgb(216, 80, 17);
    color: white;
    text-decoration: none;
    border-width: 1px;
    font-size: 100%;
    font-weight: lighter;

    margin-left: 37.5%;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 7px;

    z-index: 2;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }

  .services-button:hover {
    width: 25%;
    font-size: 0.75rem !important;

    background-color: rgb(216, 80, 17);

    border-style: solid;
    border-color: rgb(216, 80, 17);
    color: white;
    text-decoration: none;
    border-width: 1px;
    font-size: 100%;
    font-weight: lighter;

    margin-left: 37.5%;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 7px;

    text-align: center;
    z-index: 2;
  }

  .services-offering {
    z-index: 9;

    padding-left: 0%;
    display: inline;
    color: white;
    font-size: 20px;
    margin-right: 0;
    margin-top: 0%;
    padding-top: 0%;
  }
  #save {
    z-index: 9;

    margin-top: 5%;
  }
  #cloud {
    background-color: red;
    padding-right: 40%;
  }
  .api-img {
    display: block;
    margin-left: 30%;
    margin-right: 30%;
  }

  .services-offering {
    /* padding-left: 5%; */
    display: inline;
    text-align: right;
    color: white;
    font-size: 20px;
    margin-right: 0;
    margin-top: 0%;
    padding-top: 0%;
    word-wrap: break-word;
  }

  #save {
    margin-top: 5%;
  }

  .explore-research-button {
    width: 25%;
    left: 5%;

    font-size: 0.75rem;
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(17, 216, 17, 0.342);
    border-color: rgb(17, 216, 17);
    z-index: 9;
    top: 0px;
    position: absolute;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }

  .explore-research-button:hover {
    width: 25%;
    font-size: 0.75rem;

    left: 5%;
    background-color: rgb(17, 216, 17);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    border-color: rgb(17, 216, 17);
    z-index: 9;
    top: 0px;
    position: absolute;
  }
  .explore-services-button {
    left: 39%;
    width: 25%;
    font-size: 0.75rem;

    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-right: 3%;
    padding-left: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(216, 80, 17, 0.342);
    border-color: rgb(216, 80, 17);
    z-index: 9;
    top: 0px;
    position: absolute;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }
  .explore-services-button:hover {
    left: 39%;

    width: 25%;
    font-size: 0.75rem;

    background-color: rgb(216, 80, 17);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(216, 80, 17);
    z-index: 9;
    top: 0px;
    position: absolute;
  }

  .explore-blog-button {
    right: 2%;
    font-size: 0.75rem;

    width: 25%;
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(19, 19, 221, 0.349);

    border-color: rgb(19, 19, 221);
    z-index: 9;
    top: 0px;
    position: absolute;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }
  .explore-blog-button:hover {
    right: 2%;

    width: 25%;
    font-size: 0.75rem;

    background-color: rgb(19, 19, 221);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(19, 19, 221);
    z-index: 9;
    top: 0px;
    position: absolute;
  }

  .row {
    /* margin-left: -18px !important; */
  }

  .backdropDiv {
    position: relative;
    height: fit-content;
    margin-bottom: 80%;
  }
  .fixed-tickerEnter {
    width: 100%;
    position: fixed;
    z-index: 2;
    margin-top: 0%;
    margin-left: 0%;
  }
}

.ticker-entry {
  z-index: 9;
  margin-bottom: 40px;
  margin-top: 0px;
}

.offering-financials {
  padding-left: 25%;
  text-align: left;
  font-size: 15px;
  padding-bottom: 5px;
  color: rgb(255, 255, 255);
}

.value {
  display: inline;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  padding-top: 35px;
  padding-bottom: 2%;

  /* color: #0d5fcd; */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #0d5fcd;

  padding-right: 0;
  /* border-width: 2px;
  border-style: solid;
  border-color: #0d5fcd; */
}

.nation {
  left: 0;
  display: inline;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  padding-top: 35px;
  padding-bottom: 2%;
  padding-left: 0;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #07eda1;
  color: #ffffff;
}

.fintech-header {
  z-index: 9;

  margin-top: 50px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  padding-top: 35px;
  padding-bottom: 2%;
  color: rgb(226, 56, 13);
}

.api-img {
  width: 150px;
  height: 150px;
  padding-right: 0;
  margin-right: 0;
}

.particlesContainer {
  z-index: 1;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

canvas {
  z-index: 0 !important;
}

.explore-header {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  z-index: 9;
  position: absolute;
  margin-top: 19%;
  text-align: center;
  color: rgb(201, 198, 25);
}
#navButtons {
  position: absolute;
  z-index: 9;
  background-color: tomato;
  margin-top: 50%;

  width: 100%;
  color: white;
}
#dcfButtons {
  position: absolute;
  z-index: 9;
  background-color: tomato;
  margin-top: 58%;

  width: 100%;
  color: white;
}

.city-image {
  position: absolute;
  left: 0;
  width: 100%;

  filter: grayscale(0%) opacity(0.4);
}

.transition-div {
  position: absolute;
  z-index: 7;
  top: 0;
  height: 170px;
  width: 100%;
  left: 0;

  background: #23232e00;
  background: linear-gradient(0deg, #23232e00 0%, rgba(8, 23, 59, 1) 100%);
}

.launchDiv {
  width: 100%;
}
