/*large screen*/
@media only screen and (min-width: 600px) {
  .login-form {
    width: 80%;
    margin-left: calc(10% + 32px);
  }
  .subscribe-button {
    transition: 0.4s;
    width: 200px;
    margin-left: 64px;
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;

    margin-top: 5%;

    margin-bottom: 5%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(19, 19, 221, 0.349);

    border-color: rgb(19, 19, 221);
    z-index: 9;
    top: 0px;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }
  .subscribe-button:hover {
    width: 200px;

    background-color: rgb(19, 19, 221);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(19, 19, 221);
    z-index: 9;
    top: 0px;
  }

  .credentials-input {
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5%;
    background-color: rgba(64, 85, 136, 0.534);
    color: white;
    text-indent: 5px;
  }

  #emailFormRow.row {
    margin-left: 4%;
  }

  #firstName {
    color: white;
  }
  #lastName {
    color: white;
  }
  #email {
    color: white;
  }
}
/*small screen*/
@media only screen and (max-width: 600px) {
  .login-form {
    width: 80%;
    margin-left: 10%;
  }
  .subscribe-button {
    transition: 0.4s;
    width: 200px;
    right: 10%;
    margin-left: 12%;

    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;

    margin-top: 5%;

    margin-bottom: 5%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(19, 19, 221, 0.349);

    border-color: rgb(19, 19, 221);
    z-index: 9;
    top: 0px;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }
  .subscribe-button:hover {
    width: 200px;
    right: 10%;
    margin-left: 12%;

    background-color: rgb(19, 19, 221);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(19, 19, 221);
    z-index: 9;
    top: 0px;
  }

  .credentials-input {
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5%;
    background-color: rgba(64, 85, 136, 0.534);
    color: white;
    text-indent: 5px;
    padding-right: 10%;
    margin-left: 6%;
  }

  #emailFormRow.row {
    margin-left: 4%;
  }

  #firstName {
    color: white;
    margin-left: 6%;
  }
  #lastName {
    color: white;
    margin-left: 6%;
  }
  #email {
    color: white;
    margin-left: 6%;
  }
}
