.graph-container {
  width: 100% !important;
  height: 250px !important;
}

.final-amount {
  width: fit-content;
  box-shadow: 0 0 25px #29b33c;
  border-style: solid;
  border-width: 1px;
  border-color: #29b33c;
  transition: 0.3s;
  color: white;
  border-radius: 15px;
  padding: 10px;
  margin-top: 15px;

  font-weight: bold;
  background: #1eff0040;
}

/* premium button style
padding: 10px;
    border-style: solid;
    border-radius: 15px;
    border-width: 1px;
    color: #00ceff;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0 0 5px #00dbf4;
    background: linear-gradient(45deg, #7673ac, #0e0a53);
}
*/
