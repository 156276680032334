/*check mark color : #31B9C2*/

.services-logo {
  width: 300px;
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .services-window {
    transition: 0.3s;

    height: 100%;

    width: 95%;
    margin-left: 5%;
  }

  .email-form-container {
    border-radius: 10px;
    left: 27.5%;
    width: 50%;
    position: fixed;
    top: 15%;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    animation: fadeIn 0.5s;

    z-index: 10 !important;
    background-color: rgb(19, 38, 82);

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.829);
    height: 75%;
    overflow: scroll;
  }
  #services-row {
    z-index: 4 !important;

    padding-left: 1% !important;
    top: 0%;
  }

  .close-EmailForm {
    top: 2%;
    color: #92ddff21 !important;
    width: fit-content;
    border-radius: 50%;
    position: absolute;
    left: 93%;
    z-index: 9 !important;
    border-width: 1px;
    border-style: solid;

    border-color: #92ddff21;
    color: black;
  }
  .close-EmailForm:hover {
    top: 2%;

    color: rgb(184, 19, 19) !important;
    /* background-color: rgb(206, 38, 38); */
    transition: 0.5s;
    width: fit-content;
    border-radius: 50%;
    position: absolute;
    left: 93%;
    z-index: 9 !important;
    border-width: 1px;
    border-style: solid;

    border-color: rgb(184, 19, 19);
  }
}

/*small screen*/
@media only screen and (max-width: 600px) {
  .services-window {
    height: 100%;

    width: 95%;
    margin-left: 3%;
  }
  #services-row {
    z-index: 4 !important;

    padding-left: 0% !important;
    top: 0%;
  }
  .email-form-container {
    border-radius: 10px;
    left: 2% !important;
    width: 96% !important;
    position: fixed;
    top: 0%;
    margin-top: 10%;
    margin-bottom: 5%;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    animation: fadeIn 0.5s;

    z-index: 10 !important;
    background-color: rgb(19, 38, 82);

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.829);
    height: fit-content;
    max-height: 75%;
    overflow: scroll;
  }
  #servicesGradient {
    height: 100px;
    background: #e41414;
    background: linear-gradient(0deg, #e41414 0%, rgba(195, 12, 12, 0) 100%);
    margin-bottom: 80px;
  }

  .close-EmailForm {
    top: 2%;
    color: #92ddff21 !important;
    width: fit-content;
    border-radius: 50%;
    position: absolute;
    left: 90%;
    z-index: 9 !important;
    border-width: 1px;
    border-style: solid;

    border-color: #92ddff21;
    color: black;
  }
  .close-EmailForm:hover {
    top: 2%;

    color: rgb(184, 19, 19) !important;
    /* background-color: rgb(206, 38, 38); */
    transition: 0.5s;
    width: fit-content;
    border-radius: 50%;
    position: absolute;
    left: 90%;
    z-index: 9 !important;
    border-width: 1px;
    border-style: solid;

    border-color: rgb(184, 19, 19);
  }
}

.data-services-card {
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
  /*  background: rgba(0, 0, 0, 0.25);*/
  background: rgba(0, 0, 0, 0);

  border-radius: 10px; /* 5px rounded corners */
  color: rgb(204, 229, 233);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.data-services-card:hover {
  /*
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.6);
  background: rgba(255, 0, 0, 0.274);
  */
}

.dev-services-card {
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px; /* 5px rounded corners */
  color: rgb(204, 229, 233);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.dev-services-card:hover {
  /*
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.6);
  background: rgba(255, 0, 0, 0.274);
  */
}

.data-services-header {
  padding-bottom: 8%;
  color: #e41414;

  font-style: italic;

  border-bottom: 1px solid thistle;
  padding-bottom: 3px;
  margin-left: 1%;
  font-weight: bold;
}

.dev-services-header {
  padding-bottom: 8%;

  color: #e41414;

  font-style: italic;

  border-bottom: 1px solid thistle;
  padding-bottom: 3px;
  margin-left: 1%;
  font-weight: bold;
}

.checkmarkImg1 {
  margin-top: 10%;
  padding-left: 0px;
  width: 2rem;
}
.checkmarkImg2 {
  width: 60px;
  height: 60px;
}

.particlesContainer {
  z-index: 1 !important;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

h1.services-header {
  z-index: 2 !important;
}

.services-header {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  z-index: 2 !important;
  color: rgb(226, 24, 24);
  padding-top: 2%;
  padding-bottom: 2%;
}

/*accordian style*/
.accordion-body {
  padding-bottom: 0px;
  background-color: rgba(255, 255, 255, 0);

  border-radius: 10px; /* 5px rounded corners */
}

.collapse,
.collapsing {
  height: fit-content;
  padding-bottom: 0px;
}

#accordian {
  text-align: left;
}

.accordion-button {
  text-align: center;
  color: rgb(96, 161, 36);
  background-color: rgb(71, 243, 255);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
}
.accordion-button:after {
  background-color: rgba(255, 255, 255, 0);
}

.accordion-item {
  background-color: rgba(38, 36, 161, 0);
}

.collapsed {
  border-radius: 10px; /* 5px rounded corners */
  background-color: rgba(255, 255, 255, 0);

  text-align: center;
}
/*accordion-button collapsed //*/

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button.collapsed {
  text-align: center;
}

.accordion-header {
  background-color: rgba(255, 255, 255, 0);
}

.accordion-item:first-of-type .accordion-button {
  background-color: rgba(255, 255, 255, 0);
}

#services-accordian .accordion-button {
  border-radius: 5px;
  color: rgb(255, 255, 255) !important;
  background-color: rgba(20, 190, 196, 0.144) !important;
  margin-top: 2%;
  margin-bottom: 2%;
}

#services-accordian .accordion-button:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(20, 190, 196, 0.404) !important;
}

.api-key-button {
  background-color: rgba(216, 80, 17, 0.342);

  border-style: solid;
  border-color: rgb(216, 80, 17);
  color: white;
  text-decoration: none;
  border-width: 1px;
  font-size: 100%;
  font-weight: lighter;

  width: 30%;
  margin-left: 35%;

  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 7px;

  text-align: center;
  z-index: 2;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
}

.api-key-button:hover {
  transition: 0.3s;

  background-color: rgb(216, 80, 17);

  border-style: solid;
  border-color: rgb(216, 80, 17);
  color: white;
  text-decoration: none;
  border-width: 1px;
  font-size: 100%;
  font-weight: lighter;

  width: 30%;
  margin-left: 35%;

  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 7px;

  text-align: center;
  z-index: 2;
}

.blurred-background {
  animation-delay: 5s;
  animation-fill-mode: forwards;
  animation: fadeIn 0.5s;

  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

.coming-soon {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  color: rgb(255, 255, 255);
  margin-top: 5%;
  margin-bottom: 5%;
  padding-left: 2%;
  padding-right: 2%;
}

.data-intro-h3 {
  padding-top: 5%;
  padding-bottom: 5%;
}

.dev-intro-h3 {
  padding-top: 5%;

  padding-bottom: 5%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.get-in-touch {
  max-width: 800px;
  margin: 50px auto;
  position: relative;
}
.get-in-touch .title {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 3.2em;
  line-height: 48px;
  padding-bottom: 48px;
  color: #5543ca;
  background: #5543ca;
  background: -moz-linear-gradient(left, #f4524d 0%, #5543ca 100%) !important;
  background: -webkit-linear-gradient(
    left,
    #f4524d 0%,
    #5543ca 100%
  ) !important;
  background: linear-gradient(to right, #f4524d 0%, #5543ca 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.contact-form .form-field {
  position: relative;
  margin: 32px 0;
}
.contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 2px 0;
  border-color: #5543ca;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
.contact-form .input-text:focus {
  outline: none;
}
.contact-form .input-text:focus + .label,
.contact-form .input-text.not-empty + .label {
  -webkit-transform: translateY(-24px);
  transform: translateY(-24px);
}
.contact-form .label {
  position: absolute;
  left: 20px;
  bottom: 11px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #5543ca;
  cursor: text;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.contact-form .submit-btn {
  display: inline-block;
  background-color: #000;
  background-image: linear-gradient(125deg, #a72879, #064497);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  width: 200px;
  cursor: pointer;
}

.testInput {
  z-index: 50;
}

.email-input {
  width: 100%;
}

::placeholder {
  padding-left: 5px;
  color: #38df0e71;
}

.message-input {
  border-radius: 5px;
  border-color: #a0e1ff21;
  color: #38df0e !important;

  text-indent: 10px;

  background-color: #92ddff21;

  width: fit-content;

  width: 100%;
  overflow: scroll;
  resize: both;
  min-height: 200px;
}
.placeholder {
  margin-top: 2%;
}

.send-button {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 5%;
  transition: 0.2s;
  position: relative;
  width: fit-content;

  color: white;
  border-radius: 7px;
  text-decoration: transparent;
  font-weight: lighter;

  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-style: solid;
  border-width: 1px;

  background-color: rgba(17, 216, 17, 0.342);
  border-color: rgb(17, 216, 17);
  z-index: 1;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
}

.send-button:hover {
  position: relative;

  width: fit-content;

  background-color: rgb(17, 216, 17);
  color: white;
  border-radius: 7px;
  text-decoration: transparent;
  font-weight: lighter;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-style: solid;
  border-width: 1px;

  border-color: rgb(17, 216, 17);
  z-index: 9;
}

.name-input {
  width: 100%;
}

#messagePersonInfo {
  margin-left: 15%;
}

#messagePersonBody {
  justify-content: center;
  padding-left: 1%;
}

.customSolution-header {
  margin-top: 5%;
  margin-bottom: 3%;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
}

.input-containerService {
  position: relative;
  margin-bottom: 10%;
  width: 100%;
}

.ic1Service {
  left: 0;
  margin-top: 20px;
}

.inputService {
  left: 0;
  overflow: auto;
  text-overflow: ellipsis;

  background-color: #92ddff21;
  border-style: solid !important;
  border-color: #94dafa21 !important;
  border-width: 1px !important;

  border-radius: 10px;
  border: 0;
  box-sizing: border-box;
  color: #38df0e !important;
  font-size: 16px;
  outline: 0;
  /*  padding: 4px 20px 0;*/
  padding: 5% 5%;

  height: 10px;
  width: 65%;
}

.cutService {
  /*background-color: rgba(0, 136, 255, 0.473);*/
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.inputService:focus ~ .cutService,
.inputService:not(:placeholder-shown) ~ .cutService {
  transform: translateY(8px);
}

.placeholderService {
  /*font-size: 15px;*/
  color: #38df0e88;
  font-family: sans-serif;
  pointer-events: none;
  position: absolute;
  transition: transform 200ms, color 200ms;

  font-size: 90%;

  left: 5%;
  top: 10%;
  overflow: hidden;
}

.inputService:focus ~ .placeholderService,
.inputService:not(:placeholder-shown) ~ .placeholderService {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

#messageId::placeholder {
  color: #38df0e67 !important;
}

#emailId::placeholder {
  color: #38df0e67 !important;
}

#nameId::placeholder {
  color: #38df0e67 !important;
}

.misc-dev-services {
  left: 0;
}
p.misc-services {
  text-align: left;
}

div#devOfferingsRow.row {
  background-color: #fff;
  min-width: 100px !important;
}

#servicesGradient {
  height: 100px;
  background: #e41414;
  background: linear-gradient(0deg, #e41414 0%, rgba(195, 12, 12, 0) 100%);
}

#terms {
  margin-top: 75px;
  color: rgb(45, 45, 94);
}
#termsID {
  position: relative;
  z-index: 100;
}
.term-text {
  font-size: 10px;
}
.term-text:hover {
  color: aqua;
  transition: 0.3s;
}
