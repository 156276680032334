:root {
  --td-textColor: #ffffff;
  --tr-hoverHighlightColor: rgb(3 22 88);
  --td-hoverHighlightColor: rgb(9, 29, 100);
  --td-hoverTextColor: #def50b;
  --lineItem-textColor: #def50b;
  --lineItem-highlightColor: #031657;
  --th-textColor: #ffffff;
  --th-highlightColor: #3443cc;
  --table-backgroundColor: #12aae636;
}
.MuiInputBase-input {
  color: white !important;
}
#mui-62043 {
  color: white !important;
}
/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: sans-serif;
  src: url("../fonts/montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: sans-serif;
  src: url("../fonts/montserrat/Montserrat-Medium.ttf");
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

/* ------------------------------------ */
a {
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

/* ------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/* ------------------------------------ */
input {
  display: block;
  outline: none;
  border: none !important;
}

textarea {
  display: block;
  outline: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

/* ------------------------------------ */
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Table ]*/

.limiter {
  width: 0%;
  margin: 0 auto;
}

.container-table100 {
  background: hsl(0, 100%, 50%);

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 0;
  padding-bottom: 0px;
}

.MuiInputLabel-outlined {
  margin-top: 5px !important;
}

.input-field {
  border-radius: 5px;
  width: 25%;
  font-size: 50%;
}

#pad {
  padding-bottom: 0px;
}

.wrap-table100 {
  width: 1300px;
  padding-bottom: 0px;
}

/*//////////////////////////////////////////////////////////////////
[ Table ]*/

th,
td {
  padding-left: 10px;
  padding-right: 10px;
}

.column100 {
  width: 130px;
  padding-left: 25px;
}

.column100.column1 {
  width: 265px;
  padding-left: 42px;
}

.row100.head th {
  padding-top: 2px;
  padding-bottom: 2px;
}

.row100 td {
  padding-top: 5px;
  padding-bottom: 5px;
}

/*==================================================================
[ Ver6 ]*/

.table100.ver6 {
  border-radius: 10px;

  overflow: auto;
  background: var(--table-backgroundColor);
  padding-bottom: 0px;
}

.table100.ver6 table {
  background-color: transparent;
  padding-bottom: 0px;
  width: 100%;
}

.table100.ver6 td {
  font-family: sans-serif;
  font-size: 14px;
  color: var(--td-textColor);
  line-height: 1.4;
}

/* years */
.table100.ver6 th {
  font-family: sans-serif;
  font-size: 14px;
  color: var(--th-textColor);
  line-height: 1.4;
  text-transform: uppercase;

  background-color: var(--th-highlightColor);
}

.table100.ver6 th.lineItem {
  color: var(--lineItem-textColor);
  background-color: var(--lineItem-highlightColor);
}
/*whole row*/
.table100.ver6 .row100:hover td {
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
  transition: color 0.5s;

  background-color: var(--tr-hoverHighlightColor);
}

/*individual cell*/
.table100.ver6 .row100 td:hover {
  /* font-weight: bolder !important; */
  color: var(--td-hoverTextColor);
  background-color: var(--td-hoverHighlightColor);

  /* transition: 0.1s; */
}
.table100.ver6 .row100 td {
}

.table100.ver6 .row100 {
}

.row100 head {
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
  transition: color 0.5s;
  transition: ease-in;
}

.bull-image {
  width: 40px;
  /* margin-top:5px; */
  /* height:60px; */
}

#uncontrolled-tab-example-tab-bull {
  height: 100%;
}

#uncontrolled-tab-example-tab-normal {
  height: 100%;
  width: fit-content;
  margin: 0 auto;
}

.normal-image {
  width: 30px;
}

.bear-image {
  width: 40px;
}

.mb-3.nav.nav-tabs {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.weightage {
  overflow: auto;
  text-overflow: ellipsis;
  background-color: #92ddff21;
  border-style: solid !important;
  border-color: #94ddff21 !important;
  border-width: 1px !important;
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 16px;
  height: 24px;
  outline: 0;
  /* padding: 4px 20px 0; */
  /* padding: 10% 5%; */
  width: 90%;
      min-width: 50px;
  text-align: center;
  display: inline;
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .weighted-box {
    box-shadow: 0 1px 2px 0 rgb(0 0 0);
    border-radius: 15px;
    padding: 20px;
    /* margin: 10px; */
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  .weightedRed {
    box-shadow: 0 1px 2px 0 rgba(219, 17, 17, 0.863);
    transition: 0.3s;
    color: red;
    background-color: rgba(211, 24, 24, 0.103);
    border-radius: 5px;
    max-width: 50px;
  }

  .weightedGreen {
    box-shadow: 0 1px 2px 0 rgba(255, 64, 0, 0.863);
    transition: 0.3s;
    color: green;
    background-color: rgba(255, 64, 0, 0.103);
    border-radius: 5px;
    max-width: 50px;
  }

  .weightedYellow {
    box-shadow: 0 1px 2px 0 rgba(255, 255, 0, 0.863);
    transition: 0.3s;
    color: yellow;
    background-color: rgba(255, 255, 0, 0.103);
    border-radius: 5px;
    max-width: 50px;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .weighted-box {
    box-shadow: 0 1px 2px 0 rgb(0 0 0);
    border-radius: 15px;
    padding: 20px;
    /* margin: 10px; */
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .weightedRed {
    box-shadow: 0 1px 2px 0 rgba(219, 17, 17, 0.863);
    transition: 0.3s;
    color: red;
    background-color: rgba(211, 24, 24, 0.103);
    border-radius: 5px;
  }

  .weightedGreen {
    box-shadow: 0 1px 2px 0 rgba(255, 64, 0, 0.863);
    transition: 0.3s;
    color: green;
    background-color: rgba(255, 64, 0, 0.103);
    border-radius: 5px;
  }

  .weightedYellow {
    box-shadow: 0 1px 2px 0 rgba(255, 255, 0, 0.863);
    transition: 0.3s;
    color: yellow;
    background-color: rgba(255, 255, 0, 0.103);
    border-radius: 5px;
  }
}
.weighted-value {
  box-shadow: 0 0 25px #302fa4;
  border-style: solid;
  border-width: 1px;
  border-color: slateblue;
  transition: 0.3s;
  color: white;
  background-color: rgb(78 106 99 / 39%);
  border-radius: 5px;
  font-size: x-large;
}

#SaveValuationIcon {
  /* background: url(./styles/savedSidebar.png); */
  background: url(./savedSidebar.png);

  background-size: 100%;
  width: 40px;
  box-shadow: 0 4px 4px 0 #00000096;

  /*  margin-left: 20%;*/
}

#SaveValuationIcon:hover {
    cursor: pointer;
  transition: 0.2s;
  /* background: url(./styles/savedSidebar.png); */
  background: url(./savedSidebar.png);

  background-size: 100%;
  width: 40px;
  box-shadow: 0 2px 2px 0 #00000096;
  filter: grayscale(7%) opacity(0.8);

  /*  margin-left: 20%;*/
}

.SaveValuationIcon-wrap {
  /* margin-left: 15px;
  margin-right: 15px; */
  height: 40px;
  margin: 0 auto;
  margin-top: 10px;
}

.custom {
  margin-left: 1px !important;
}
