.tenk-image {
  margin-left: 5px;
  width: 35px;
  background-color: rgba(255, 255, 255, 0.712);
  border-radius: 5px;
}

.tenk-image:hover {
  margin-left: 5px;
  width: 35px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  transition: 0.4s;
}

/* .forms-container{
padding: 3px;
background-color: white;
} */

.chart-div {
  width: 80%;
  height: 300px;
  position: absolute;
  background: #19232fe6;
  margin-top: -325px;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 70px;
  box-shadow: 0 2px 2px 0 #f9f9f9b8;
  z-index: 100;
}

.chart-icon {
  fill: rgb(28, 224, 116);
  width: 25px;
  background: #00f1ff2b;
  border-radius: 5px;
  margin-left: 5px;
}

.chart-icon:hover {
  transition: 0.5s;
  background: #00f2ff7e;
}
