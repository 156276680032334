/*small screen*/

@media only screen and (max-width: 600px) {
  .email-info {
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
    transition: 0.3s;
    background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
    border-radius: 10px; /* 5px rounded corners */
    width: 100%;
    height: 70px;
  }
  .user-info {
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
    transition: 0.3s;
    background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
    border-radius: 10px; /* 5px rounded corners */
    width: 100%;
    height: 45px;
  }
}

/*large screen*/

@media only screen and (min-width: 600px) {
  .email-info {
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
    transition: 0.3s;
    background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
    border-radius: 10px; /* 5px rounded corners */
    width: 40%;
    height: 70px;
    margin-left: calc(50% - 20%);
  }
  .user-info {
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
    transition: 0.3s;
    background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
    border-radius: 10px; /* 5px rounded corners */
    width: 40%;
    height: 45px;
    margin-left: calc(50% - 20%);
  }
}

.avatar-container {
  position: absolute;
  z-index: -1;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
  background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
  border-radius: 10px; /* 5px rounded corners */
  width: 80%;
  height: 375px;
  margin-left: calc(50% - 40%);
}
.access-container {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
  background-color: rgb(19, 36, 80); 
  border-radius: 10px; 5px rounded corners */
}

.account-page {
  position: relative;
  width: 100%;
}

#about {
  margin-right: 30%;
  position: absolute !important;
  width: 600px;
  background-color: aliceblue;
}
.email-notif {
  width: 50px;
  height: 50px;
}
.logout-btn {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);

  position: fixed;
  top: calc(0% + 25px);
  right: calc(0% + 25px);
  border-radius: 50%;

  fill: red;
  width: 50px;
  height: 50px;
  transition: 0.3s;
  background-color: rgba(255, 168, 168, 0.103);
  padding: 5px;
}

.logout-btn:hover {
  border-radius: 50%;
  transition: 0.3s;
  background-color: rgba(255, 168, 168, 0.247);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.349);
}

.notModIcon {
  filter: grayscale(0%) opacity(0.55);

  width: 50px;
}
.notPremiumIcon {
  filter: grayscale(0%) opacity(0.55);

  height: 25px;
  width: 50px;
}
