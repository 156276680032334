
.details-btn{
  color: #30ff4e;
    font-family: Courier New;
   
    border-radius: 5px;
    width: fit-content;
    /* font-weight: bold; */
    margin: 0 auto;
    padding: 1px 13px;
    font-style: italic;
    border-width: 1px;
    background: #30ff4e1f;
    box-shadow: 0px 0px 3px #30ff4e;
    font-size: 11px;
    transition: 0.5s;
}
.details-btn:hover {
  cursor: pointer;
 
  background: #30ff4f49;
      transition: 0.5s;
 }

.collection-container {
  /* border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: black; */
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  /* width: fit-content; */
  margin: 0 auto;
  max-width: 1150px;
}
.collection-label {
  /* left: 10%; */
  /* float: left; */
}

/*large screen*/
@media only screen and (min-width: 600px) {
  .price-container{
    margin-left: 5px;
  }
        .assumptions-container {
          margin-left: 5px;
        }
  .price-style {
      background: #1a2d437e;
      /* border-top-left-radius: 10px; */
      /* border-bottom-left-radius: 10px; */
      padding: 10px;
      color: #d2d5d9;
      box-shadow: 0 3px 10px 0 #000000a6;
      border-style: solid;
        border-width: 1px;
        border-color: #21354d;
        border-style: solid;
          border-width: 1px;
          border-color: #21354d;
    }
  .ticker-style{
    background: linear-gradient(91deg, #1a2d43, #1a2d437e);
          border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 10px;
      color: #d2d5d9;
      box-shadow: 0 3px 10px 0 #000000a6;
      border-style: solid;
        border-width: 1px;
        border-color: #21354d;
  }
.marketcap-style{
    background: linear-gradient(91deg, #1a2d437e, #1a2d43);    
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    color: #d2d5d9;
    box-shadow: 0 3px 10px 0 #000000a6;
    font-family: 'Barlow Condensed';
      font-size: 20px;
      border-style: solid;
        border-width: 1px;
        border-color: #21354d;
        border-style: solid;
          border-width: 1px;
          border-color: #21354d;
}
  .valuation-details-container{
margin-left: 66px;
width: calc(100% - 66px);
  }
  .add-collection {
    position: absolute;
    width: 15%;
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    left: 10%;
    float: left;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(17, 216, 17, 0.342);
    border-color: rgb(17, 216, 17);
    z-index: 9;
    margin-top: 40px;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
    transition: 0.4s;
  }

  .add-collection:hover {
    width: 15%;
    position: absolute;

    background-color: rgb(17, 216, 17);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(17, 216, 17);
    z-index: 9;
  }

  .saved-valuations-screen {
    width: calc(100% - 84px) !important;
    left: 74px;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .valuation-table::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .valuation-table::-webkit-scrollbar-thumb {
    border-radius: 50px;
    border: 0.5px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }

  .valuation-table {
    /* margin-left: calc(50% - 575px); */
    margin: 0 auto;

    position: relative;
    margin-top: 20px;
    max-width: 1150px;
    overflow: scroll;
    background-color: #09062b;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
    box-shadow: 0 2px 4px 0 rgb(0, 0, 0);
    scrollbar-width: 10px !important;
    scrollbar-color: orange !important;
  }

  .table-bottom-header {
    margin: 0 auto;

    position: relative;
    /* margin-left: calc(50% - 575px); */

    max-width: 1150px;
    height: 55px;
    overflow: scroll;
    background-color: #050325;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: white;
    box-shadow: 0 2px 4px 0 rgb(0, 0, 0);
  }
}

/*small screen*/
@media only screen and (max-width: 600px) {
  .marketcap-style {
      background: #1a2d43;
   
      padding: 10px;
      color: #d2d5d9;
      box-shadow: 0 3px 10px 0 #000000a6;
      font-family: 'Barlow Condensed';
        font-size: 20px;
    }
  .price-style{
    background: #1a2d43;
      /* border-top-left-radius: 10px; */
      /* border-bottom-left-radius: 10px; */
      padding: 10px;
      color: #d2d5d9;
      box-shadow: 0 3px 10px 0 #000000a6;
  }
  .ticker-style{
    background: #1a2d43;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 10px;
      color: #d2d5d9;
      box-shadow: 0 3px 10px 0 #000000a6;
      border-style: solid;
        border-width: 1px;
        border-color: #21354d;
  }
  .valuation-details-container {

  }
  .add-collection {
    width: 25%;
    left: 5%;

    margin-top: 10px;

    font-size: 0.75rem;
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;

    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    background-color: rgba(17, 216, 17, 0.342);
    border-color: rgb(17, 216, 17);
    z-index: 9;
    top: 0px;
    box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
  }

  .add-collection:hover {
    width: 25%;
    font-size: 0.75rem;

    left: 5%;
    background-color: rgb(17, 216, 17);
    color: white;
    border-radius: 7px;
    text-decoration: transparent;
    font-weight: lighter;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-style: solid;
    border-width: 1px;

    border-color: rgb(17, 216, 17);
    z-index: 9;
    top: 0px;
  }

  .saved-valuations-screen {
    width: 100% !important;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .valuation-table {
    position: relative;
    margin-top: 20px;
    max-width: 1150px;
    overflow: scroll;
    background-color: #09062b;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
    box-shadow: 0 2px 4px 0 rgb(0, 0, 0);
    scrollbar-width: 10px !important;
    scrollbar-color: orange !important;
  }

  .table-bottom-header {
    position: relative;

    max-width: 1150px;
    height: 55px;
    overflow: scroll;
    background-color: #050325;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: white;
    box-shadow: 0 2px 4px 0 rgb(0, 0, 0);
  }
}

.editIcon {
  width: 30px;
  color: #151047;
  /* border-style: solid;
  border-width: 1px;
  border-color: #0c0c45; */
  border-radius: 50px;
  padding: 5px;
}

.editIcon:hover {
  transition: 300ms;
  background-color: #201f2e;
}

.td-notes {
  border-style: solid;
  border-width: 1px;
  border-color: #4e8894;
  border-radius: 15px;
  /* padding: 5px; */
}

#notes-text {
  /* font-size:smaller;
  color:red; */
  background-color: #201f36;
  scrollbar-width: none;

  min-height: 40px;
  width: 200px;
  padding-left: 20px;
  padding-top: 5px;

  border-radius: 15px;
}

#notes-text:hover {
  /* font-size:smaller;
  color:red; */
  background-color: #201f36;
}

.title-headers {
  background: #09062b;
  background: radial-gradient(circle, rgb(24, 8, 82) 0%, #09062b);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.save-changes-button {
  background-color: yellow;
}
.save-changes-button-modified {
  background-color: red;
}

.last-table-header {
  border-top-right-radius: 15px;
}
.first-table-header {
  border-top-left-radius: 15px;
}

.plusIcon {
  width: 40px;
  color: #151047;
  /* border-style: solid;
  border-width: 1px;
  border-color: #0c0c45; */
  border-radius: 50px;
  /* padding: 5px; */
}

.plusIcon:hover {
  transition: 300ms;
  background-color: #201f2e;
}

.blurred-background {
  /* filter: blur(9px);
  animation-delay: 5s;
  animation-fill-mode: forwards;
  animation: fadeIn 0.5s;

  z-index: 9;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%; */
}

#collectionContainer {
  padding: 5px;
  border-width: 0.5px;
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(11, 99, 8);
}
