.customCashReturnedDiv{
  top: 47.5%;
    position: absolute;
    /* background: red; */
    margin-left: 14%;
 }
  
 .cashReturnedInput{
    background: #164a504a;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: white;
    max-width: 55px;
    padding: 4px;
    display: inline;
 }
  
 .cashReturnedLabel{
  display: inline;
    background: #0000ff00;
    padding: 5px;
    border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    border-style: solid;
    border-color: #ffffff6b;
    border-width: 1px;
    color: rgb(76 159 169);
 }
  
 .zeroedDiv{
 top: 46%;
  position: absolute;
  margin-left: 7%;
  border-radius: 50%;
  background: #2d1350;
  width: 35px;
  padding: 5px;
  box-shadow: 0 5px 5px 0 #00000061;
  color: #c420b3;
  
 }
 .zeroedDiv:hover {
  cursor: pointer;
  transition: 300ms;
  background-color: rgb(48, 21, 83);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
 }
 .hiddenValueInput{
 height: 100%;
 background: #164a504a;
 border-top-right-radius: 5px;
 border-bottom-right-radius: 5px;
 color: white;
 max-width: 55px;
 }
 .hiddenValueContainer{
  display: flex;
 }
 .hiddenValue{
  display: inline;
    padding-left: 7px;
        padding-right: 7px;
    border-style: solid;
    border-width: 1px;
     border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-color: #76809a;
      color: rgb(76 159 169);
 }
  
 .cashReturned{
  position: absolute;
  top:73%;
 }
 .add-valuation-form {
  position: fixed;
  z-index: 100;
  top: 10vh;
  height: 80vh;
  width: 80vw;
  left: 10vw;
  /* background-color: rgb(19, 38, 82); */
  background-color: rgb(11, 23, 53);
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.55);
  overflow: scroll;
  overflow-x:hidden;
 }
 :root {
  --yearsInput-row: 20%;
  --resetButton-row: 40%;
  --yearsDiscounted-row: 60%;
  --intrinsicValue-row: 100%;
  --width-col: 10%;
  --years-fontStyle: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  --years-fontColour: rgb(121, 189, 196);
  
  --discounted-shadow: 0 1px 2px 0 rgba(219, 17, 17, 0.863);
  --discounted-paddingLeft: 1%;
  --discounted-paddingRight: 1%;
  --discounted-radius: 5px;
  --discounted-bg: rgba(211, 24, 24, 0.103);
  --discounted-color: rgb(221, 23, 23);
  
  --intrinsicValue-shadow: 0 1px 2px 0 rgba(229, 255, 0, 0.863);
  --intrinsicValue-paddingLeft: 1%;
  --intrinsicValue-paddingRight: 1%;
  --intrinsicValue-radius: 5px;
  --intrinsicValue-bg: rgba(187, 255, 0, 0.103);
  --intrinsicValue-color: rgb(251, 255, 0);
 }
  
 .input-field {
  /* color :  = text color*/
  background-color: #38df0ec7;
 }
  
 @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,600,300,800);
  
 .input-container {
  position: relative;
  /* margin-bottom: 10%; */
  width: 100%;
 }
  
 #initalFcf {
  left: 0;
 }
  
 .help-icon {
  fill: rgb(3, 66, 2);
  margin-top: -20%;
  margin-left: 90%;
 }
  
 .ic1 {
  left: 0;
  margin-top: 20px;
 }
  
 .ic2 {
  margin-top: 10px;
 }
  
 .input {
  left: 0;
  overflow: auto;
  text-overflow: ellipsis;
  
  background-color: #92ddff21;
  border-style: solid !important;
  border-color: #94dafa21 !important;
  border-width: 1px !important;
  
  border-radius: 10px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 16px;
  outline: 0;
  /*  padding: 4px 20px 0;*/
  padding: 5% 5%;
  
  height: 10px;
  width: 85%;
  padding-bottom: 7%;
 }
  
 .form {
  left: 0;
 }
  
 .dcfInput {
  overflow: auto;
  text-overflow: ellipsis;
  
  background-color: #92ddff4b;
  border-radius: 10px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 16px;
  height: 100%;
  outline: 0;
  /*  padding: 4px 20px 0;*/
  padding: 10% 5%;
  
  width: 100%;
 }
  
 .cut {
  /*background-color: rgba(0, 136, 255, 0.473);*/
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
 }
  
 .cut-short {
  width: 50px;
 }
  
 .input:focus ~ .cut,
 .input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
 }
  
 .placeholder {
  /*font-size: 15px;*/
  color: #38df0e88;
  font-family: sans-serif;
  pointer-events: none;
  position: absolute;
  transition: transform 200ms, color 200ms;
  
  font-size: 90%;
  
  left: 5%;
  top: 10%;
  overflow: hidden;
 }
  
 .input:focus ~ .placeholder,
 .input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
 }
  
 .input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
 }
  
 .input:focus ~ .placeholder {
  color: #4bf309;
 }
  
 /*dcf edited highligth*/
 .custom-border {
  outline: black;
 }
  
 .custom-border:valid {
  border-bottom: 1px solid #000000;
 }
  
 ::placeholder {
  /* Most modern browsers support this now. */
  color: rgb(255, 255, 255) !important;
  font-size: 12px;
 }
  
 .inputCF {
  /* overflow: auto; */
  text-overflow: ellipsis;
  
  background-color: #92ddff21;
  border-style: solid !important;
  border-color: #94ddff21 !important;
  border-width: 1px !important;
  border-radius: 10px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 16px;
  /* height: 100%; */
  height: fit-content;
  outline: 0;
  /*  padding: 4px 20px 0;*/
  padding: 10% 5%;
  width: 90%;
 }
 .input10CF {
  overflow: auto;
  text-overflow: ellipsis;
  
  background-color: #92ddff21;
  border-style: solid !important;
  border-color: #94ddff21 !important;
  border-width: 1px !important;
  border-radius: 10px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 16px;
  height: fit-content;
  outline: 0;
  /*  padding: 4px 20px 0;*/
  padding: 10% 0%;
  width: 90%;
 }
  
 .edited {
  background-color: #3dff7e4b;
  border-radius: 12px;
  border-style: solid !important;
  border-color: #94ddff21 !important;
  border-width: 1px !important;
  border: 0;
  box-sizing: border-box;
  color: rgb(9, 231, 46);
  font-size: 16px;
  height: fit-content;
  outline: 0;
  padding: 10% 5%;
  width: 90%;
 }
  
 .discountedCFLabel {
  border-radius: 5px;
  width: max-content;
  margin-left: 0;
  margin-right: 0;
  color: white;
  background-color: #92ddff4b;
 }
  
 .col-lg {
  height: 100%;
  width: 100%;
  padding-right: 0;
 }
  
 .yearsDiv {
  margin-top: 1.5%;
  right: 0;
  height: 100%;
  width: 100%;
 }
  
 .overflow-container {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
  background-color: rgb(19, 36, 80);
  border-radius: 10px; /* 5px rounded corners */
  
  /*outline-color: #4baa1f;*/
  /*  outline-style: groove;*/
  right: 0;
  position: relative;
  
  /* min-width: 100%;*/
  
  max-width: 100%;
  resize: unset;
  width: 1000px;
  height: 300px;
  margin: 0 auto;
  /*background-color: #dacd19;*/
  
  overflow-x: scroll;
  overflow-y: hidden;
  /*  overflow-y: scroll;*/
  
  min-width: 0px;
  min-height: 300px; /*as much as it needs to be*/
  /*  margin-bottom: 10%;*/
 }
  
 /* Small screens */
 @media only screen and (max-width: 600px) {
  .calculators-container {
    /* background-color: #000000; */
    /* position:absolute;
 width:100%; */
    margin-bottom: 100px;
  }
  
  .cagr-graph-container {
    /* position:absolute; */
    /* width:50%; */
    /* height:400px; */
    /* position:relative;
  
   width: 75% !important;
  height: 250px !important; */
    margin-bottom: 25%;
  }
  
  .ci-inputs {
    /* position:relative;
  
  width:25%; */
  }
  .overflow-container {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.55);
    transition: 0.3s;
    background-color: rgb(19, 36, 80);
    border-radius: 10px; /* 5px rounded corners */
  
    /*outline-color: #4baa1f;*/
    /*  outline-style: groove;*/
    right: 0;
    position: relative;
  
    /* min-width: 100%;*/
  
    max-width: 100%;
    resize: unset;
    width: 1000px;
    height: 300px;
    margin: 0 auto;
    /*background-color: #dacd19;*/
  
    overflow-x: scroll;
    overflow-y: hidden;
    /*  overflow-y: scroll;*/
  
    min-width: 0px;
    min-height: 300px; /*as much as it needs to be*/
    margin-bottom: 10%;
  }
 }
  
 .overflow-container::-webkit-scrollbar {
  -webkit-appearance: none;
 }
  
 .overflow-container::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 0.5px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
 }
  
 .dcfOverflow {
  /*  background-color: blue;*/
  right: 0;
  position: relative;
  resize: unset;
  width: 900px;
  height: 100%;
  margin: 0 auto;
  
  /* overflow-x: scroll; */
  /*  overflow-y: scroll;*/
  
  min-width: 0px;
  
  min-height: 300px; /*as much as it needs to be*/
 }
  
 .year1 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  left: 9%;
  position: absolute;
  top: 2%;
 }
  
 .year2 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 19%;
  top: 2%;
 }
 .year3 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 29%;
  top: 2%;
 }
 .year4 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 39%;
  top: 2%;
 }
 .year5 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 49%;
  top: 2%;
 }
 .year6 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 59%;
  top: 2%;
 }
 .year7 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 69%;
  top: 2%;
 }
 .year8 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 79%;
  top: 2%;
 }
 .year9 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 89%;
  top: 2%;
 }
 .year10 {
  font-family: var(--years-fontStyle);
  color: var(--years-fontColour);
  font-weight: bold;
  position: absolute;
  left: 99%;
  padding-right: 5%;
  top: 2%;
 }
 .year10Terminal{
  font-family: var(--years-fontStyle);
    color: var(--years-fontColour);
    font-weight: bold;
    position: absolute;
    left: 109%;
    padding-right: 5%;
    top: 2%;
 }
  
 .year1Input {
  position: absolute;
  max-width: 10%;
  left: 7%;
  height: fit-content;
  top: var(--yearsInput-row);
 }
  
 .year2Input {
  position: absolute;
  left: 17%;
  max-width: 10%;
  
  top: var(--yearsInput-row);
 }
 .year3Input {
  position: absolute;
  left: 27%;
  max-width: 10%;
  
  top: var(--yearsInput-row);
 }
 .year4Input {
  position: absolute;
  left: 37%;
  max-width: 10%;
  
  top: var(--yearsInput-row);
 }
 .year5Input {
  position: absolute;
  left: 47%;
  max-width: 10%;
  
  top: var(--yearsInput-row);
 }
 .year6Input {
  position: absolute;
  left: 57%;
  max-width: 10%;
  
  top: var(--yearsInput-row);
 }
 .year7Input {
  position: absolute;
  left: 67%;
  max-width: 10%;
  
  top: var(--yearsInput-row);
 }
 .year8Input {
  position: absolute;
  left: 77%;
  max-width: 10%;
  
  top: var(--yearsInput-row);
 }
 .year9Input {
  position: absolute;
  left: 87%;
  max-width: 10%;
  
  top: var(--yearsInput-row);
 }
 .year10Input {
  position: absolute;
  left: 97%;
  min-width: 10%;
  min-height: 100%;
  
  top: var(--yearsInput-row);
 }
 .year10TerminalInput {
  position: absolute;
  left: 107%;
  min-width: 10%;
  min-height: 100%;
  
  top: var(--yearsInput-row);
 }
 .resetDiv {
  border-radius: 50%;
  position: absolute;
  left: 1%;
  top: var(--resetButton-row);
  height: 35px;
  width: 35px;
  padding: 5px;
  background-color: rgb(45, 19, 80);
  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
 }
 .resetDiv:hover {
    cursor: pointer;
  transition: 300ms;
  border-radius: 50%;
  position: absolute;
  left: 1%;
  top: var(--resetButton-row);
  height: 35px;
  width: 35px;
  background-color: rgb(48, 21, 83);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
 }
 .year1Discounted {
  position: absolute;
  max-width: var(--width-col);
  top: var(--yearsDiscounted-row);
  
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  /* max-height: 8%; */
  height: fit-content;
  overflow: hidden;  /* width: 10%; */
  left: 8%;
 }
  
 .year2Discounted {
  position: absolute;
  left: 18%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
 .year3Discounted {
  position: absolute;
  left: 28%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
 .year4Discounted {
  position: absolute;
  left: 38%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
 .year5Discounted {
  position: absolute;
  left: 48%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
 .year6Discounted {
  position: absolute;
  left: 58%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
 .year7Discounted {
  position: absolute;
  left: 68%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
 .year8Discounted {
  position: absolute;
  left: 78%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
 .year9Discounted {
  position: absolute;
  left: 88%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
 .year10Discounted {
  position: absolute;
  left: 98%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius); /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
  
 .year10TerminalDiscounted {
  position: absolute;
  left: 108%;
  max-width: var(--width-col);
  
  top: var(--yearsDiscounted-row);
  box-shadow: var(--discounted-shadow);
  transition: 0.3s;
  color: var(--discounted-color);
  background-color: var(--discounted-bg);
  border-radius: var(--discounted-radius);
  /* 5px rounded corners */
  padding-left: var(--discounted-paddingLeft);
  padding-right: var(--discounted-paddingRight);
  height: fit-content;
  overflow: hidden;
 }
  
 .intrinsicValueBull {
  top: 86%;
  position: absolute;
  background-color: khaki;
  width: fit-content;
  
  transition: 0.3s;
  border-radius: var(--intrinsicValue-radius);
  /* 5px rounded corners */
  
  margin-left: 2px;
  padding-left: var(--intrinsicValue-paddingLeft);
  padding-right: var(--intrinsicValue-paddingRight);  
  box-shadow: 0 1px 2px 0 rgb(219 17 17 / 86%);
     color: red;
    background-color: rgba(211, 24, 24, 0.103);
 }
  
 .intrinsicValueBull {
  top: 86%;
  position: absolute;
  background-color: khaki;
  width: fit-content;
  
   transition: 0.3s;
   border-radius: var(--intrinsicValue-radius);
  /* 5px rounded corners */
  
  margin-left: 2px;
  padding-left: var(--intrinsicValue-paddingLeft);
  padding-right: var(--intrinsicValue-paddingRight);
      box-shadow: 0 1px 2px 0 rgb(255 64 0 / 86%);
        color: green;
        background-color: rgba(255, 64, 0, 0.103);
 }
  
            
               
                
  
 .intrinsicValue {
  top: 86%;
  position: absolute;
  background-color: khaki;
  width: fit-content;
  
  box-shadow: var(--intrinsicValue-shadow);
  transition: 0.3s;
  color: var(--intrinsicValue-color);
  background-color: var(--intrinsicValue-bg);
  border-radius: var(--intrinsicValue-radius); /* 5px rounded corners */
  
  margin-left: 2px;
  padding-left: var(--intrinsicValue-paddingLeft);
  padding-right: var(--intrinsicValue-paddingRight);
 }
  
 .intrinsic-value-div {
  overflow: hidden;
  background-color: yellow;
  
  top: 10%;
  left: 100px;
  height: 50%;
 }
  
 .logoTest {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: blue;
  background: yellow;
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
  width: 100%;
 }
  
 .arrow1 {
  color: rgb(76 159 169 / 49%);
 }
 .arrow2 {
  color: rgba(76, 160, 169, 0.698);
 }
  
 .year1Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 14.5%;
 }
 .year2Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 24.5%;
 }
  
 .year3Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 34.5%;
 }
  
 .year4Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 44.5%;
 }
 .year5Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 54.5%;
 }
 .year6Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 64.5%;
 }
 .year7Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 74.5%;
 }
 .year8Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 84.5%;
 }
 .year9Arrow {
  top: 0;
  
  width: 3%;
  position: absolute;
  left: 94.5%;
 }
  
 .year10Arrow {     
  top: 0;
        width: 3%;
        position: absolute;
        left: 104.5%;
 }
  
 .cfHeader {
  position: absolute;
  /* font-size: 1.75vw; */
  color: rgb(121, 189, 196);
  top: 22%;
  
  left: 0;
 }
 .dcfHeader {
  position: absolute;
  /* font-size: 1.75vw; */
  color: rgb(121, 189, 196);
  
  top: 60%;
  left: 0;
 }
  
 .resetButton {
  color: #00000000;
  background: url(./undoArrow.png);
  background-size: 100%;
  height: 100%;
  width: 100%;
 }
  
 /* large screen */
 @media only screen and (min-width: 600px) {
  .calculators-container {
    /* background-color: #000000; */
    /* position:absolute;
 width:100%; */
  }
  
  .ci-inputs {
    margin-top: 35%;
    /* position:relative;
  
  width:25%; */
  }
  .cagr-graph-container {
    margin-bottom: 5%;
    /* position:absolute; */
    /* width:50%; */
    /* height:400px; */
    /* position:relative;
  
   width: 75% !important;
  height: 250px !important; */
  }
 }
  
 .switch-holder {
  display: flex;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: -8px -8px 15px rgba(255, 255, 255, 0.7),
    10px 10px 10px rgba(0, 0, 0, 0.3),
    inset 8px 8px 15px rgba(255, 255, 255, 0.7),
    inset 10px 10px 10px rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  align-items: center;
 }
  
 .switch-label {
  width: 150px;
 }
  
 .switch-label i {
  margin-right: 5px;
 }
  
 .switch-toggle {
  height: 40px;
  margin-bottom: 10px;
 }
  
 .switch-toggle input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -2;
 }
  
 .switch-toggle input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  width: 320px;
  height: 40px;
  border-radius: 20px;
  margin: 0;
  cursor: pointer;
  box-shadow: inset -8px -8px 15px rgba(255, 255, 255, 0.6),
    inset 10px 10px 10px rgba(0, 0, 0, 0.25);
 }
  
 .switch-toggle input[type="checkbox"] + label::before {
  position: absolute;
  content: "Compound Interest";
  font-size: 13px;
  text-align: center;
  line-height: 25px;
  top: 8px;
  left: 8px;
  width: 150px;
  height: 25px;
  border-radius: 20px;
  color: #fff;
  background-color: #00b33c;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.5), 1px 2px 3px #010103;
  
  transition: 0.3s ease-in-out;
 }
  
 .switch-toggle input[type="checkbox"]:checked + label::before {
  left: 50%;
  content: "CAGR";
  color: #fff;
  background-color: #4254ed;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.5), 3px 3px 5px #010103;
 }
  
 .cagr-calculator {
  /* background-color: #0e176438; */
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 2px 4px 2px #4253ed73;
  margin-bottom: 15px;
 }
  
 .ci-calculator {
  /* background-color: #0857223a; */
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 2px 4px 2px #00b33c54;
  
  margin-bottom: 15px;
 }
 .cagr-output {
  border-radius: 15px;
  padding: 20px;
  font-size: xx-large;
  margin-bottom: 15px;
  color: white;
  height: fit-content;
  width: fit-content;
  font-weight: bold;
  
  box-shadow: 0 0 25px #302fa4;
  border-style: solid;
  border-width: 1px;
  border-color: slateblue;
  transition: 0.3s;
  background: #4254ed40;
 }
 .return-label {
  position: relative;
  font-size: xx-large;
  color: #4254ed;
  display: inline;
 }
  
 .saveValuation {
  top: 79%;
  position: absolute;
  width: fit-content;
  
  box-shadow: var(--intrinsicValue-shadow);
  transition: 0.3s;
  color: rgb(0, 0, 0);
  
  background-color: var(--intrinsicValue-bg);
  border-radius: var(--intrinsicValue-radius); /* 5px rounded corners */
  
  margin-left: 2px;
  padding-left: var(--intrinsicValue-paddingLeft);
  padding-right: var(--intrinsicValue-paddingRight);
  border-radius: 15%;
  background-color: rgb(45, 19, 80);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
 }
  
 .saveValuation:hover {
  top: 79%;
  position: absolute;
  width: fit-content;
  
  box-shadow: var(--intrinsicValue-shadow);
  transition: 0.3s;
  color: rgb(0, 0, 0);
  
  background-color: var(--intrinsicValue-bg);
  border-radius: var(--intrinsicValue-radius); /* 5px rounded corners */
  
  margin-left: 2px;
  padding-left: var(--intrinsicValue-paddingLeft);
  padding-right: var(--intrinsicValue-paddingRight);
  border-radius: 15%;
  background-color: rgb(45, 19, 80);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 300ms;
  background-color: rgb(48, 21, 83);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
 }
 /*
  
 .resetDiv {
  border-radius: 50%;
  position: absolute;
  left: 1%;
  top: var(--resetButton-row);
  height: 35px;
  width: 35px;
  padding: 5px;
  background-color: rgb(45, 19, 80);
  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
 }
 .resetDiv:hover {
  transition: 300ms;
  border-radius: 50%;
  position: absolute;
  left: 1%;
  top: var(--resetButton-row);
  height: 35px;
  width: 35px;
  background-color: rgb(48, 21, 83);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
 } */
 .textfield_label {
  color: #ffffff !important;
  /* background-color: #4bf309 !important; */
  /* border-color: #808097; */
 }
 .textfield_props {
  color: #ffffff !important;
  /* border-color: #808097; */
 }

 .advanced-label{
  color: white;
  position: absolute;
  margin-top: 13.2%;
  margin-left: 16%;
 }
  
 .pencilIcon {
  width: 30px;
  color: #151047;
  /* border-style: solid;
  border-width: 1px;
  border-color: #0c0c45; */
  border-radius: 50px;
  padding: 5px;
 }
 .fireIcon {
  width: 30px;
  color: #151047;
  /* border-style: solid;
  border-width: 1px;
  border-color: #0c0c45; */
  border-radius: 50px;
 }
 .mos {
  width: 35px;
  color: #151047;
  /* border-style: solid;
  border-width: 1px;
  border-color: #0c0c45; */
  border-radius: 50px;
  padding: 5px;
 }
  
 .save-new-valuation-button {
  width: 125px;
  margin-top: 20px;
  color: white;
  /* background-color: orange; */
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(0, 97, 0);
  border-width: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.55);
 }
 .save-new-valuation-button:hover {
  transition: 0.5s;
  color: white;
  background-color: rgb(39, 139, 39);
 }
  
 .close-btn {
  fill: rgb(255, 255, 255);
  width: 20px;
 }
  
 .close-btn:hover {
  transition: 0.5s;
  fill: red;
  width: 20px;
 }
 