.quickStats-container {
  font-family: cursive;
  color: chartreuse;
  font-weight: 300;
}
hr.line {
  border: 2px solid rgb(132, 247, 255);
  border-radius: 15px;
  margin-top: 0;
  margin-bottom: 0;
}
#quick {
  border-radius: 5px;
  width: 105.5%;
  margin-top: 10px;
  /* border-style: solid;
  border-width: 1px;
  border-color: rgb(0, 0, 0); */

  background: linear-gradient(#161f34, #1a2a51);
  box-shadow: 0 0 5px #302fa4;
  color: #42ebff;
}

.endQuick {
  margin-bottom: -35px;
}
