.css-5xe99f-MuiLinearProgress-bar1{
background-color: #00ff33 !important;
}
.css-eglki6-MuiLinearProgress-root{
background-color: #105432 !important;
    height: 8px !important;
    border-radius: 5px;
}

.removePadding{
    padding-left: 0 !important;
        padding-right: 0 !important;
}
.removePaddingRight{
        /* padding-right: 0 !important; */
}
.navInput{
width: 100%;
    text-align: center;
    background: #2f4f4f7a;
    border-radius: 5px;
    color:white;
}
.reportNav{
        width: fit-content;
            margin: 0 auto;
 }

.previous-buy{
    width: fit-content;
        margin: 0 auto;
        border-style: solid;
        padding: 5px;
        border-radius: 5px;
        border-width: 1px;
        box-shadow: 0 0 10px 0;
        color: hwb(242 0% 0%);
        box-shadow: 0 0 25px #b0db5591;
        border-style: solid;
        border-width: 1px;
        border-color: #adff00;
        transition: 0.3s;
        color: white;
        background-color: rgb(98 106 78 / 62%);
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
}

.see-portfolio-btn{
    width: fit-content;
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 5px;
        padding: 3px 15px;
        border-style: solid;
        border-width: 1px;
     
        background: #ffffff1f;
        box-shadow: 1px 1px black;
        transition: 0.3s;
        color: white;
        
    }
        .see-portfolio-btn:hover {
            background: #ffffff59;
            cursor: pointer;
        }

.investor-stats{
padding: 10px;
    margin: 10px;
    border-radius: 5px;
    background: radial-gradient(#1ec2c914, #1ec2c938);
    border-style: solid;
    border-width: 1px;
    border-color: #1ec2c9;
}
.your-valuation{
        width: fit-content;
            margin: 0 auto;
            border-style: solid;
            padding: 5px;
            border-radius: 5px;
            border-width: 1px;
            box-shadow: 0 0 10px 0;
            color: hwb(242 0% 0%);
            box-shadow: 0 0 25px #302fa4;
            border-style: solid;
            border-width: 1px;
            border-color: slateblue;
            transition: 0.3s;
            color: white;
            background-color: rgb(78 106 99 / 39%);
            border-radius: 5px;

}
.your-add-valuation{
width: fit-content;
    margin: 0 auto;
    border-style: solid;
    padding: 5px;
    border-radius: 5px;
    border-width: 1px;
    box-shadow: 0 0 10px 0;
    color: #0800ff;
    box-shadow: 0 0 25px #302fa4;
    border-style: solid;
    border-width: 1px;
    border-color: slateblue;
    transition: 0.3s;
    color: white;
    background-color: rgb(78 106 99 / 39%);
    border-radius: 5px;
}
.your-add-valuation:hover {
    cursor: pointer;
    background-color: rgba(78, 106, 99, 0.853);
}
.arrow-style{
    fill:turquoise;
}
.superinvestor-peek {
    /* background-image: url(https://cloudfront-us-east-2.images.arcpublishing.com/reuters/M5Z3A47SNBMDJKJTT2MBVCDLRE.jpg); */
    /* background-size: cover;
    border-radius: 50%;
    height: 100px;
    width: 100px; */
    /* margin-left: 150px; */
    /* background-position: -10px; */
}

.share-twitter-btn{
    width: fit-content;
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 5px;
        padding: 3px 15px;
        border-style: solid;
        border-width: 1px;
        background: #ffffff1f;
        box-shadow: 1px 1px black;
                    transition: 0.3s;
    
}
.share-twitter-btn:hover {
    background: #ffffff59;
    cursor: pointer;
}

#tickerStyle{
    font-size: 30px;
        color: gold;
            align-self: center;
}


.superinvestor-pic{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-size: cover !important;
            margin: 5px;
        border-style: solid;
            border-color: #c91e1e;
            border-width: 2px;
            margin: 0 auto;
}

.red-change{
    padding: 5px;
    background-color: #b81010;
    color:white;
    border-radius: 5px;
    width: fit-content;
            margin: 0 auto;
            box-shadow: 0 0 7px #b81010
    
}
.green-change{
    padding: 5px;
    background-color: limegreen;
    color: white;
    border-radius: 5px;
        width: fit-content;
                margin: 0 auto;
                box-shadow: 0 0 7px limegreen
}

.new-label{
padding: 5px;
    background-color: yellow;
    box-shadow: 0 0px 5px 0 yellow;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    /* width: fit-content; */
    width: fit-content;
    font-style: italic;
            margin-top: 5px;
}

.negative-addition{
border-style: solid;
    color: red;
    border-width: 1px;
    border-radius: 10px;
    width: fit-content;
    padding: 5px;
        margin: 0 auto;
                margin-top: 5px;
}
.positive-addition{
color: green;
border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    width: fit-content;
    padding:5px;
        margin: 0 auto;
        margin-top: 5px;
}
.new-label{
padding: 5px;
    background-color: yellow;
            box-shadow: 0 0 15px 0 yellow;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    width: fit-content;
    font-style: italic;
        margin: 0 auto;
                margin-top: 5px;
}
.sold-label{
    padding: 5px;
        background-color: red;
        border-radius: 5px;
        box-shadow: 0 0 15px 0 red;
        color: white;
        font-weight: bold;
        width: -moz-fit-content;
        width: fit-content;
        font-style: italic;
        margin: 0 auto;
                margin-top: 5px;
}
.holding-container{
    background: linear-gradient(47deg, #030e26, #08173b);
        margin: 10px;
        border-radius: 10px;
        padding: 10px;
        /* border-style: solid; */
        box-shadow: 0 0px 5px 0 #095b74;
    
    }
.nochange-label{
    border-style: solid;
    border-color: #0800ff;
    border-width: 1px;
    color: white;
    width: fit-content;
    border-radius: 5px;
    padding: 5px;
    margin: 0 auto;
            margin-top: 5px;
}

/* small screen */
@media only screen and (max-width: 600px) {
    .report-nav-container {
            
        }
    .profile-container {
           
            font-size: 20px;
            font-weight: bold;
        }
.holdings-list{     
     margin-bottom: 100px;
}
.superinvestor-container {
    border-radius: 15px;
    background: linear-gradient(45deg, #03041d, #41427761);
    box-shadow: 0 0 15px #01009c;
    width: 100%;
    margin-left: 0;
    margin-bottom: 50px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #0800ff;
}
}


/* large screen */
@media only screen and (min-width: 600px) {
    .report-nav-container {
            width: calc(100% - 66px);
            margin-left: 66px;
        }
    .profile-container {
            width: calc(100% - 66px);
            margin-left: 66px;
            font-size: 20px;
            font-weight: bold;
        }

    .holdings-list {
    width: calc(100% - 66px);
        margin-left: 66px;
    }
      
        .superinvestor-container {
            border-radius: 15px;
            background: linear-gradient(45deg, #03041d, #41427761);
            box-shadow: 0 0 15px #01009c;
            width: calc(100% - 66px);
            margin-left: 66px;
            margin-bottom: 50px;
            padding: 10px;
            border-style: solid;
            border-width: 1px;
            border-color: #0800ff;
                        transition: 0.3s;
        }

        .superinvestor-container:hover{
                        background: linear-gradient(45deg, #06072d, #414277a4);
        }
}