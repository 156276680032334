.calculators-page-dcf {
  /* background-color: rgba(0, 0, 0, 0.6); */
  max-width: fit-content;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  margin-bottom: 20px;
}

/* Small screens */
@media only screen and (max-width: 600px) {
  #mobile-margin {
    margin-top: 10px;
  }

  .screener-card {
    /* margin-top: 550px; */
    overflow: scroll;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
    transition: 0.3s;
    background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
    border-radius: 10px; /* 5px rounded corners */
    margin-bottom: 100px;
  }
  .screener-info {
    color: white;
    margin-top: 350px;
    margin-left: 64px auto;
    margin-right: 0 auto;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .screener-card {
    /* margin-top: 550px; */
    overflow: scroll;
    /* margin-left: calc(50% - 550px); */
    margin: 0 auto;
    width: fit-content;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
    transition: 0.3s;
    background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
    border-radius: 10px; /* 5px rounded corners */
    margin-bottom: 100px;
  }
  .screener-info {
    color: white;
    margin-top: 350px;
    margin-left: 64px;
  }
}

.screener-card::-webkit-scrollbar {
  -webkit-appearance: none;
}

.screener-card::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 0.5px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

/* .login-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
  background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
/* border-radius: 10px; 5px rounded corners */
/* }
.login-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.6);
}   */

.card {
  /* Add shadows to create the "card" effect */

  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  transition: 0.3s;
  background-color: rgb(19, 36, 80); /*rgb(24, 43, 87)*/
  border-radius: 10px; /* 5px rounded corners */
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.6);
}

.container-scroll {
  height: 100%;
  width: 100%;
  /*padding: 2px 16px;*/
}

.container-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.container-scroll::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 0.5px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.container-scroll-vertical::-webkit-scrollbar:vertical {
  -webkit-appearance: none;
}

.container-scroll-vertical::-webkit-scrollbar-thumb:vertical {
  border-radius: 50px;
  border: 0.5px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

/* Add some padding inside the card container */
.container {
  height: 100%;
  width: 100%;
  /*padding: 2px 16px;*/
}

.bottom-buffer {
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}

.stickyTickerAndBasicInfo {
  height: 2rem;
}

.row {
  margin-left: -15px !important;
}
