/* discussion color : #15E1E1 */

/* Large screens */
@media only screen and (min-width: 600px) {
  .discussion-window {
    overflow-y: scroll;
    overflow-x: hidden;
    transition: 0.3s;

    height: 100%;

    width: 95%;
    margin-left: 5%;
  }
  #personInfo {
    left: 0;
    color: white;

    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100%;
  }

  #nationInfo {
    color: rgb(255, 255, 255);

    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
    font-size: larger;
    font-style: italic;
    width: 100%;
  }
  .accountIcon {
    height: 50px;
    width: 50px;
  }

  .featured-posts {
    margin-top: 5%;
  }
  .nation-search {
    margin-left: 0%;
    display: inline;
    border: 4px;
    background-color: rgba(21, 109, 21, 0);
    border-radius: 5px;
    color: white;
    text-indent: 45px;
  }

  .nation-search::placeholder {
    color: rgba(255, 255, 255, 0.26) !important;
    margin-right: 5%;
  }
  .peopleIcon {
    position: absolute;
    width: 3%;
    padding-bottom: 0.5%;
  }

  .make-post-container {
    position: fixed !important;
    left: 90%;
    top: 80%;
  }
  .modIcon {
    width: 50px;
  }
  .premiumIcon {
    height: 25px;
    width: 50px;
  }

  #postContainer {
    margin-left: 4%;
    margin-right: 3%;
    margin-bottom: 4%;
  }
  #post {
    color: white;
    background-color: #1c2d53;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  }

  #poster {
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    background: rgb(36, 54, 97);
    background: radial-gradient(
      circle,
      rgba(36, 54, 97, 1) 0%,
      rgba(12, 28, 68, 1) 100%
    );
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  }
  .nationSearchContainer {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 2%;
    display: inline;
    border-radius: 10px;
    /* width: fit-content; */
    border-style: solid;
    border-color: #15e1e1;
    border-width: 1px;
    padding-right: 2%;
    padding-left: 2%;
  }

  .searchDiscussionContainer {
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 2%;
    display: inline;
    border-radius: 10px;
    /* width: fit-content; */
    border-style: solid;
    border-color: #15e1e1;
    border-width: 1px;
    padding-right: 2%;
    padding-left: 2%;
  }
  .top-container {
    position: absolute;
    background: rgb(36, 54, 97);
    background: radial-gradient(
      circle,
      rgba(36, 54, 97, 1) 0%,
      rgba(12, 28, 68, 1) 100%
    );
    border-radius: 30px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    padding-top: 2%;
    padding-bottom: 2%;
    width: 95%;
  }
}

/*small screen*/
@media only screen and (max-width: 600px) {
  .discussion-window {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;

    width: 100%;
  }
  #personInfo {
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100%;
    padding-left: 10%;
  }

  #nationInfo {
    color: rgb(255, 255, 255);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
    /* font-size: larger; */
    font-size: 0.9rem !important;

    font-style: italic;
    width: 100%;
    padding-left: 10%;
  }
  .accountIcon {
    height: 50px;
    width: 50px;
    /* padding-right: 25%; */
  }

  .featured-posts {
    margin-bottom: 25%;
  }
  .nation-search {
    margin-left: 0%;
    display: inline;
    border: 4px;
    background-color: rgba(21, 109, 21, 0);
    border-radius: 5px;
    color: white;
    text-indent: 40px;
  }

  .peopleIcon {
    position: absolute;
    width: 8%;
    margin-left: 2%;
    padding-bottom: 0.5%;
  }

  .nation-search::placeholder {
    color: rgba(255, 255, 255, 0.26) !important;
    font-size: 10px;
  }

  .make-post-container {
    position: fixed !important;
    left: 75%;
    top: 79%;
    z-index: 10;
  }
  .modIcon {
    height: 20px;
    width: 45px;
  }
  .premiumIcon {
    height: 20px;
    width: 45px;
  }
  #personInfo {
    color: white;

    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100%;
  }

  #nationInfo {
    color: rgb(255, 255, 255);

    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
    font-size: larger;
    font-style: italic;
    width: 100%;
  }
  #postContainer {
    width: 92%;
    margin-left: 2%;
    margin-bottom: 10%;
    padding-left: 5%;
  }
  #post {
    color: white;
    background-color: #1c2d53;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
    margin-left: 6%;
  }

  #poster {
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    background: rgb(36, 54, 97);
    background: radial-gradient(
      circle,
      rgba(36, 54, 97, 1) 0%,
      rgba(12, 28, 68, 1) 100%
    );
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
  }
  .nationSearchContainer {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 90%;
    margin-right: 20%;
    border-radius: 10px;
    border-style: solid;
    border-color: #15e1e1;
    border-width: 1px;
  }

  .searchDiscussionContainer {
    margin-left: 15%;

    padding-top: 15px;
    padding-bottom: 15px;
    width: 90%;
    border-radius: 10px;
    border-style: solid;
    border-color: #15e1e1;
    border-width: 1px;
  }
  .top-container {
    position: relative;
    background: rgb(36, 54, 97);
    background: radial-gradient(
      circle,
      rgba(36, 54, 97, 1) 0%,
      rgba(12, 28, 68, 1) 100%
    );
    border-radius: 30px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .make-post-container {
    position: fixed !important;
    left: 73%;
    top: 70%;
    z-index: 10;
  }
}

.switch-button {
  position: absolute;
  left: 45%;

  background: rgba(255, 255, 255, 0.56);
  border-radius: 30px;
  overflow: hidden;
  width: 50%;
  text-align: left;
  font-size: 18px;
  letter-spacing: 1px;
  color: #155fff;
  padding-right: 6%;
}
.switch-button:before {
  content: "Video";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  display: flex;
  align-items: center;
  left: 68.5%;
  z-index: 3;
  pointer-events: none;
}
.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(89%);
  transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #fff;
  height: 100%;
  width: 60%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}

.left-menu {
  padding-left: 25%;
}

.toggle-screen {
}

#toggle {
  padding-top: 0%;
  padding-bottom: 0%;
  margin-left: 0%;
  width: 100%;
  background-color: yellow;
  border-radius: 30px;
}
#community {
  background-color: rgba(0, 0, 255, 0.411);
  border-radius: 30px;
  padding-top: 1%;
  padding-bottom: 1%;
}

.makePostImg {
  position: fixed;

  background-color: rgba(8, 23, 59, 1);
  border-radius: 20px;
  padding: 10px;
  width: 76px;
  height: 76px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.55);
}
.makePostImg:hover {
  border-style: solid;
  border-color: #15e1e1;
  border-width: 1px;
  transition: 0.2s;
}

.join-nation-container {
  width: 100%;
  background-color: blue;
}

.join-text {
  display: inline;
  color: rgb(7, 219, 7);
}
.search-discussion-text {
  display: inline;
  color: rgb(7, 219, 7);
}

.featured-discussions-header {
  color: #15e1e1;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bolder;
  padding-top: 2%;

  margin-bottom: 3%;
}

.row {
}

#infoCol {
}

/********************* Common *********************/

.inhabit-button {
  margin-left: 2%;
  font-style: normal;
  font-size: smaller;
  width: fit-content;

  color: white;
  border-radius: 7px;
  text-decoration: transparent;
  font-weight: lighter;

  border-style: solid;
  border-width: 1px;

  background-color: rgba(17, 216, 17, 0.342);
  border-color: rgb(17, 216, 17);
  z-index: 9;
  top: 0px;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0);
}

.inhabit-button:hover {
  margin-left: 2%;
  font-style: normal;
  font-size: smaller;
  width: fit-content;

  background-color: rgb(17, 216, 17);
  color: white;
  border-radius: 7px;
  text-decoration: transparent;
  font-weight: lighter;

  border-style: solid;
  border-width: 1px;

  border-color: rgb(17, 216, 17);
  z-index: 9;
  top: 0px;
}

.post-header {
  color: chartreuse;
  margin-top: 2%;
  margin-bottom: 2%;

  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
