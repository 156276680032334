@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;400;600&display=swap');/*body rgba(8, 23, 59, 1)*/
 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
}
 
::-webkit-scrollbar {
 width: 0;
 background: transparent;
}
 
.tv-scroll-wrap--horizontal ::-webkit-scrollbar {
 color: #12083d;
 scrollbar-width: 20px;
}
 
* {
 font-family: Verdana, Geneva, Tahoma, sans-serif;
 scrollbar-width: none !important;
}
 
html {
 scrollbar-width: none !important;
 
 width: 100%;
 margin: 0;
 padding: 0;
 box-sizing: border-box;
 /* overflow-x:hidden; */
}
 
body {
 scrollbar-width: none !important;
 
 box-sizing: border-box;
 width: 100%;
 padding: 0;
 margin: 0;
 justify-content: center;
 align-items: center;
 min-height: fit-content;
 text-align: center;
 
 background: rgba(8, 23, 59, 1); /*rgba(8, 49, 59, 1)*/
 /* overflow-x:hidden; */
 
 /* background: rgb(28, 39, 199);
 background: radial-gradient(
   circle,
   rgba(28, 39, 199, 1) 0%,
   rgba(14, 19, 107, 1) 100%
 );
 */
}
 
#root {
 scrollbar-width: none !important;
 
 width: 100%;
 height: 100%;
}
 
h1 {
 margin: 0;
 margin-bottom: 0.5rem;
}
 
.MuiInputLabel-outlined {
 color: white !important;
}
 
.MuiFormControl-fullWidth {
 width: 100% !important;
 background: rgba(0, 0, 0, 0.5) !important;
 height: 100% !important;
}
 
.services-window {
 height: 100%;
 width: 100%;
}
 
.container-fluid {
 width: 100%;
 height: 100%;
}
 
.center-screen {
 display: flex;
 justify-content: center;
 align-items: center;
 text-align: center;
 min-height: 100vh;
}
 
:root {
 font-size: 16px;
 font-family: "sans-serif";
 --text-primary: #b6b6b6;
 --text-secondary: #ececec;
 --bg-primary: rgb(3, 14, 37);
 --bg-secondary: rgb(0, 0, 0);
 --transition-speed: 600ms;
}
 
main {
 margin-left: 5rem;
 padding: 1rem;
}
 
.navbar {
 border-top-right-radius: 20px;
 border-bottom-right-radius: 20px;
 position: fixed; /*was fixed*/
 overflow: scroll;
 -webkit-backface-visibility: hidden;
 
 left: 0px;
 
 background: var(--bg-primary);
 
 transition: width 600ms ease;
 padding: 0;
 margin: 0;
 width: fit-content;
 height: 50%;
}
 
.navbar-nav {
 height: 100%;
 list-style: none;
 padding: 0;
 margin: 0;
 display: flex;
 flex-direction: column;
 align-items: center;
 top: 0;
 bottom: 0;
 left: 0;
}
 
.navItemSidebar {
 width: 100%;
 padding-left: 0;
 left: 0;
 margin-left: 0;
}
 
.navItemSidebar:hover {
 text-indent: 10%;
}
 
.navItemSidebar:last-child {
 margin-top: auto;
}
 
.navLinkSidebar {
 display: flex;
 align-items: center;
 height: 5rem;
 color: var(--text-primary);
 text-decoration: none;
 filter: grayscale(15%) opacity(0.7);
 transition: var(--transition-speed);
 left: 0;
 padding-left: 0;
 padding-right: 2%;
 text-align: left;
 margin-left: 0;
 border-radius: 30px;
}
 
.navLinkSidebar:hover {
 filter: grayscale(0%) opacity(1);
 background: var(--bg-secondary);
 color: var(--text-secondary);
 border-radius: 30px;
}
 
.link-text {
 display: none;
 margin-left: 1rem;
}
 
.navLinkSidebar svg {
 width: 1rem;
 min-width: 1rem;
 margin: 0 1.5rem;
}
 
.fa-group {
 height: 59px;
}
 
.fa-primary {
 color: #ff7eee;
}
 
.fa-secondary {
 /*color: #df49a6;*/
 color: #e5ff00;
}
 
.fa-primary,
.fa-secondary {
 transition: var(--transition-speed);
}
 
.logo {
 font-weight: bold;
 text-transform: uppercase;
 margin-bottom: 1rem;
 text-align: center;
 color: var(--text-secondary);
 background: var(--bg-secondary);
 
 font-size: 1.5rem;
 letter-spacing: 0.3ch;
 width: 100%;
}
 
.logo svg {
 transform: rotate(0deg);
 transition: var(--transition-speed);
}
 
.logo-text {
 display: inline;
 position: absolute;
 left: -999px;
 transition: var(--transition-speed);
 
 margin-left: 0px;
 margin-right: 0px;
 width: 50px;
 height: 50px;
}
 
.navbar:hover .logo svg {
 transform: rotate(-180deg);
}
 
.priceType {
 width: fit-content;
 margin: 0 auto;
 padding: 10px;
 border-radius: 10px;
 margin-top: 20px;
 background: radial-gradient(#181f50, #0b1735);
}
 
.priceActive {
 display: inline;
 color: rgb(255, 217, 0);
}
.priceInactive {
 display: inline;
 color: rgba(180, 180, 180, 0.541);
}
 
.scaleActive {
 display: inline;
 /* margin-left: 5px; */
 padding: 10px;
 background: linear-gradient(#161f34, #1a2a51);
 /* border-width: 1px;
 border-color: yellow;
 border-style: solid; */
 box-shadow: 0 0 10px #fcff00;
 
 color: yellow;
 border-radius: 10px;
 margin-right: 5px;
}
.scaleInactive {
 display: inline;
 /* margin-left: 5px; */
 padding: 10px;
 background: linear-gradient(#161f34, #1a2a51);
 border-radius: 10px;
 transition: 0.2s;
 margin-right: 5px;
 
 box-shadow: 0 0 5px #302fa4;
 color: #42ebff;
}
 
.scaleInactive:hover {
 display: inline;
 /* margin-left: 5px; */
 padding: 10px;
 background: linear-gradient(#161f34, #1a2a51);
 border-radius: 10px;
 transition: 0.2s;
 margin-right: 5px;
 
 color: #ffffff;
 box-shadow: 0 0 10px #fcff00;
}
 
/* Small screens */
@media only screen and (max-width: 600px) {
 .react-calculator {
     height: 500px !important;
     width: 400px !important;
   }
 html {
   scrollbar-width: none !important;
 
   width: 100%;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   overflow-x: hidden;
 }
 
 body {
   scrollbar-width: none !important;
 
   box-sizing: border-box;
   width: 100%;
   padding: 0;
   margin: 0;
   justify-content: center;
   align-items: center;
   min-height: fit-content;
   text-align: center;
 
   background: rgba(8, 23, 59, 1); /*rgba(8, 49, 59, 1)*/
   overflow-x: hidden;
 
   /* background: rgb(28, 39, 199);
 background: radial-gradient(
   circle,
   rgba(28, 39, 199, 1) 0%,
   rgba(14, 19, 107, 1) 100%
 );
 */
 }
 
 .dcf {
   width: 95%;
   margin-left: 5%;
 }
 .navbar {
   bottom: 0;
   width: 100%;
   height: 5rem;
   border-radius: 0px !important;
 }
 
 #fixedFinancials {
   border-radius: 3px;
   display: inline;
   left: 39% !important;
   width: 55% !important;
   background-color: rgb(0 0 0 / 50%) !important;
   padding-top: 8px;
   padding-bottom: 8px;
 
   position: fixed;
   top: "0px";
   z-index: 9;
   color: white;
 }
 
 .logo {
   display: none;
 }
 
 .navbar-nav {
   flex-direction: row;
 }
 
 .navLinkSidebar {
   justify-content: center;
 }
 
 main {
   margin: 0;
 }
 
 .parentDiv {
   position: relative;
   height: 100%;
   width: 95%;
   left: -5%;
   margin-bottom: 15%;
 }
 
 .fill-window {
   height: 100%;
   position: relative;
 
   /* width: 100%;
   left: 4%; */
   justify-content: top;
 }
 .stickyTickerAndBasicInfo {
   padding-left: 0;
 }
 .baseDiv {
   margin-left: 5%;
 }
 .baseHeader {
   margin-top: 2%;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   font-weight: bold;
   position: relative;
   z-index: 9;
 
   color: white;
 }
 
 .baseAutocompleteContainer {
   margin-top: 40%;
 
   position: relative;
   z-index: 9;
   margin-left: 33%;
 }
 .financialsDiv {
   height: 100%;
   width: 100%;
   margin-left: 1%;
 }
 
 .circle {
   width: 30px;
   height: 30px;
   background: rgba(0, 255, 255, 0.315);
   border-radius: 50%;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   overflow: hidden;
   margin: auto;
   animation: circle 6s linear infinite;
 }
 
 .outer {
   width: 250px;
   height: 250px;
   border: 1px solid rgba(204, 204, 204, 0.363);
   position: absolute;
   top: 20%;
   /* left: 0 auto;
   right: 0 auto; */
   margin-left: calc(50% - 125px);
   border-radius: 50%;
 }
}
 
@keyframes circle {
 0% {
   transform: rotate(0deg) translate(-140px) rotate(0deg);
 }
 100% {
   transform: rotate(360deg) translate(-140px) rotate(-360deg);
 }
}
 
/* Landscape mode*/
@media (orientation: landscape) {
 .stickyTickerAndBasicInfo {
   padding-left: 2%;
 }
}
 
/* Large screens */
@media only screen and (min-width: 600px) {
 .react-calculator {
     height: 500px !important;
     width: 400px !important;
   }
 .fill-window {
   height: 100%;
   position: relative;
 
   width: calc(100% - 64px);
   left: 64px;
   justify-content: top;
 }
 
 .dcf {
   margin-top: 30px;
   width: 90%;
   margin-left: 8%;
 }
 .navbar {
   top: 0;
   /*max-width: 9%;*/
   height: 100%; /*was 100%*/
 }
 #fixedFinancials {
   border-radius: 3px;
   display: inline;
   left: 39% !important;
   width: 60% !important;
   background-color: rgb(0 0 0 / 50%) !important;
   padding-top: 8px;
   padding-bottom: 8px;
 
   position: fixed;
   top: "0px";
   z-index: 9;
   color: white;
 }
 .circle {
   width: 30px;
   height: 30px;
   background: rgba(0, 255, 255, 0.315);
   border-radius: 50%;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   overflow: hidden;
   margin: auto;
   animation: circle 6s linear infinite;
 }
 
 .outer {
   width: 250px;
   height: 250px;
   border: 1px solid rgba(204, 204, 204, 0.363);
   position: absolute;
   top: 7%;
   bottom: 0;
   left: 64px;
   right: 0;
   margin: auto;
   border-radius: 50%;
 }
 .navbar:hover {
   /* max-width: 9%;*/
 
   transition: var(--transition-speed);
 
   width: fit-content;
 }
 
 .navbar:hover .link-text {
   display: inline;
 }
 
 .navbar:hover .logo svg {
   margin-left: 11rem;
 }
 
 .navbar:hover .logo-text {
   left: 25%;
   width: 85px;
   height: 85px;
 }
 
 .parentDiv {
   position: relative;
   height: 100%;
 
   width: 95%;
   left: 3%;
 }
 
 .baseDiv {
   margin-left: 5%;
 }
 .baseHeader {
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   font-weight: bold;
   position: relative;
   z-index: 9;
   margin-top: 2%;
   margin-left: 64px;
   color: white;
 }
 
 .baseAutocompleteContainer {
   position: absolute;
   width: 300% !important;
   z-index: 9;
   justify-content: center;
   margin-left: -25%;
   margin-top: 40%;
 }
 .financialsDiv {
   height: 100%;
   width: calc(100% - 36px);
   margin-left: 36px;
 }
}
 
.dark {
 --text-primary: #fbff00;
 /*    --text-primary: #b6b6b6;*/
 
 --text-secondary: #b3ff00;
 --bg-primary: #23232e;
 --bg-secondary: #141418;
}
 
.light {
 --text-primary: #1f1f1f;
 --text-secondary: #000000;
 --bg-primary: #ffffff;
 --bg-secondary: #e4e4e4;
}
 
.solar {
 --text-primary: #576e75;
 --text-secondary: #35535c;
 --bg-primary: #fdf6e3;
 --bg-secondary: #f5e5b8;
}
 
.theme-icon {
 display: none;
}
 
.dark #darkIcon {
 display: block;
}
 
.light #lightIcon {
 display: block;
}
 
.solar #solarIcon {
 display: block;
}
 
/*loader*/
 
@keyframes pulse {
 from {
   opacity: 1;
   transform: scale(1);
 }
 to {
   opacity: 0.25;
   transform: scale(0.75);
 }
}
 
.pulse-container {
 right: 0;
 width: 200px;
 display: flex;
 justify-content: space-between;
 align-items: center;
}
 
.pulse-bubble {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 background-color: #1eff00a6;
}
 
.pulse-bubble-1 {
 animation: pulse 0.4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
 animation: pulse 0.4s ease 0.2s infinite alternate;
}
.pulse-bubble-3 {
 animation: pulse 0.4s ease 0.4s infinite alternate;
}
 
.center-loader {
 position: fixed;
 right: 50%;
 left: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
 text-align: center;
 min-height: 100vh;
}
 
.services-btn {
 background-color: red;
}
.enter-btn {
 background-color: rgb(227, 227, 248);
}
 
.sidebar-img-btn {
 margin-left: 15px;
 margin-right: 15px;
 height: 30px;
 width: 30px;
}
 
#ForumIcon {
 background: url(./styles/blogSidebar.png);
 background-size: 100%;
 height: 30px;
 width: 30px;
 left: 0;
 /*  margin-left: 20%;*/
}
 
#SuperinvestorsIcon {
 background: url(./styles/superinvestors.png);
 background-size: 100%;
 height: 30px;
 width: 30px;
 left: 0;
 /*  margin-left: 20%;*/
}
 
 
#ServicesIcon {
 background: url(./styles/servicesSidebar.png);
 background-size: 100%;
 height: 30px;
 width: 30px;
 left: 0;
 /*  margin-left: 20%;*/
}
 
#ResearchIcon {
 background: url(./styles/researchSidebar.png);
 background-size: 100%;
 height: 30px;
 width: 30px;
 left: 0;
 /*  margin-left: 20%;*/
}
 
#ValuationIcon {
 background: url(./styles/savedSidebar.png);
 background-size: 100%;
 height: 30px;
 width: 30px;
 left: 0;
 /*  margin-left: 20%;*/
}
 
#AccountIcon {
 background: url(./styles/accountSidebar.png);
 background-size: 100%;
 height: 30px;
 width: 30px;
 left: 0;
}
 
.resize {
 width: 15px;
 height: auto;
}
 
.resize {
 width: 15px;
 height: auto;
}
 
.newParentDiv {
 position: static;
 height: 100%;
 width: 100%;
}
 
.row {
 /* --bs-gutter-x: 0rem */
}
 
 
.calculatorBody {
 border: 2px solid gray;
 width: 30vw;
 height: 47vw;
 background: #eaeaea;
 margin: auto;
 margin-top: 3vw;
 border-radius: 10px;
 box-shadow: 0px 0px 20px black;
 
}
 
.whole-screen {
 background: black;
 height: 10vw;
 margin: auto;
 margin-top: 2vw;
 width: 90%;
 border-radius: 10px;
 box-shadow: 0px 0px 5px black;
}
 
.special-button-container {
 display: flex;
 padding: 1vw;
 margin: 1vw;
 margin-bottom: 0px;
 margin-top: 0px;
 padding-bottom: 0px;
}
 
.second-special-btn-cont-margin-top {
 margin-left: 2.9vw;
}
 
.special-btn-cont-margin-top {
 margin-top: 1vw;
}
 
.special-button {
 background: #0c69ff;
 height: 2vw;
 width: 3vw;
 border-radius: 5px;
 margin: .2vw;
 padding: 2px;
 color: black;
 font-weight: bold;
 text-align: center;
 box-shadow: 0px 0px 15px gray;
}
 
.special-button:active {
 background: red;
 color: white;
 font-weight: bold;
}
 
.special-button-name {
 margin: auto;
 padding: inherit;
}
 
.numbers-container {
 display: flex;
 flex-wrap: wrap;
 margin: 2.5vw;
 margin-left: 2.6vw
}
 
.each-number {
 background: #0c69ff;
 height: 5vw;
 width: 5vw;
 border-radius: 5px;
 text-align: center;
 margin-left: 1vw;
 margin-bottom: 1vw;
 font-weight: bold;
 box-shadow: 0px 0px 10px gray;
}
 
.each-number:active {
 background: red;
 color: white;
 font-weight: bold;
}
 
.number-button-txt {
 margin: auto;
 margin-top: 1.2vw;
}
 
.functions-container {
 display: flex;
 width: 80%;
 margin: auto;
 margin-left: 3.0vw;
}
 
.each-function {
 background: #0c69ff;
 height: 3vw;
 width: 3vw;
 border-radius: 5px;
 font-weight: bold;
 margin: auto;
 text-align: center;
 margin-top: 1vw;
 padding-top: .3vw;
 box-shadow: 0px 0px 15px gray;
}
 
.each-function:active {
 background: red;
 color: white;
 font-weight: bold;
}
 
.screen-for-calc {
 height: 87%;
 width: 92%;
 border-radius: 10px;
 margin-left: .05vw;
 background: black;
 color: yellow;
 font-weight: bold;
 font-size: 20px;
 resize: none;
 padding-left: 1vw;
 padding-right: 1vw;
 padding-top: 1vw;
}
 
@media only screen and (min-width: 375px) and (max-width: 667px) {
 
 h1 {
   font-size: 2rem;
 }
 
 h2 {
   font-size: 2.5rem;
 }
 
 h4 {
   font-size: 1.5rem;
 }
 
 .calculatorBody {
   border: 2px solid gray;
   background: #eaeaea;
   margin-top: 5vw;
   margin-bottom: 5vw;
   border-radius: 10px;
   box-shadow: 0px 0px 20px black;
   width: auto;
   height: 160vw;
 
 }
 
 .whole-screen {
   background: black;
   height: 30vw;
   margin: auto;
   margin-top: 2vw;
   width: 90%;
   border-radius: 10px;
   box-shadow: 0px 0px 5px black;
 }
 
 .special-button {
   background: #0c69ff;
   border-radius: 5px;
   margin-top: 5vw;
   margin-left: 2vw;
   color: black;
   font-weight: bold;
   text-align: center;
   box-shadow: 0px 0px 15px gray;
   width: 41vw;
   height: 10vw;
 }
 
 .second-mobile-special {
   margin-left: 0px;
 }
 
 .functions-container {
   display: flex;
   width: 100%;
   margin-left: 0px;
   margin-right: 0px;
   margin-top: 8vw;
 }
 
 .each-function {
   background: #0c69ff;
   border-radius: 5px;
   font-weight: bold;
   text-align: center;
   margin-top: 1vw;
   padding-top: 2vw;
   box-shadow: 0px 0px 15px gray;
   height: 15vw;
   width: 25vw;
   margin-left: 1vw;
   margin-right: 1vw;
 }
 
 .numbers-container {
   display: flex;
   flex-wrap: wrap;
   margin-left: 0px;
   margin-right: 0px;
   width: 100%;
   height: 35%;
 
 }
 
 .each-number {
   background: #0c69ff;
   height: 15vw;
   width: 20vw;
   border-radius: 5px;
   text-align: center;
   margin: auto;
   font-weight: bold;
   box-shadow: 0px 0px 10px gray;
 }
 
 .screen-for-calc {
   height: 100%;
   width: 98%;
   border-radius: 10px;
   margin-left: .05vw;
   background: black;
   color: yellow;
   font-weight: bold;
   font-size: 20px;
   resize: none;
   padding-left: 1vw;
   padding-right: 1vw;
   padding-top: 1vw;
 }
 
}
 
@media only screen and (max-width: 375px) {
 
 h1 {
   font-size: 2rem;
 }
 
 h2 {
   font-size: 1.8rem;
 }
 
 h4 {
   font-size: 1.2rem;
 }
 
 .calculatorBody {
   border: 2px solid gray;
   background: #eaeaea;
   margin-top: 5vw;
   margin-bottom: 5vw;
   border-radius: 10px;
   box-shadow: 0px 0px 20px black;
   width: auto;
   height: 160vw;
 
 }
 
 .whole-screen {
   background: black;
   height: 30vw;
   margin: auto;
   margin-top: 2vw;
   width: 90%;
   border-radius: 10px;
   box-shadow: 0px 0px 5px black;
 }
 
 .special-button {
   background: #0c69ff;
   border-radius: 5px;
   margin-top: 5vw;
   margin-left: 2vw;
   color: black;
   font-weight: bold;
   text-align: center;
   box-shadow: 0px 0px 15px gray;
   width: 41vw;
   height: 10vw;
 }
 
 .second-mobile-special {
   margin-left: 0px;
 }
 
 .functions-container {
   display: flex;
   width: 100%;
   margin-left: 0px;
   margin-right: 0px;
   margin-top: 8vw;
 }
 
 .each-function {
   background: #0c69ff;
   border-radius: 5px;
   font-weight: bold;
   text-align: center;
   margin-top: 1vw;
   padding-top: 2vw;
   box-shadow: 0px 0px 15px gray;
   height: 15vw;
   width: 25vw;
   margin-left: 1vw;
   margin-right: 1vw;
 }
 
 .numbers-container {
   display: flex;
   flex-wrap: wrap;
   margin-left: 0px;
   margin-right: 0px;
   width: 100%;
   height: 35%;
 
 }
 
 .each-number {
   background: #0c69ff;
   height: 15vw;
   width: 20vw;
   border-radius: 5px;
   text-align: center;
   margin: auto;
   font-weight: bold;
   box-shadow: 0px 0px 10px gray;
 }
 
 .screen-for-calc {
   height: 100%;
   width: 98%;
   border-radius: 10px;
   margin-left: .05vw;
   background: black;
   color: yellow;
   font-weight: bold;
   font-size: 20px;
   resize: none;
   padding-left: 1vw;
   padding-right: 1vw;
   padding-top: 1vw;
 }
 
}
 
 
@media only screen and (min-width: 668px) and (max-width: 1024px) {
 
 h1 {
   font-size: 4rem;
 }
 
 h2 {
   font-size: 3.5rem;
 }
 
 h4 {
   font-size: 3rem;
 }
 
 .calculatorBody {
   border: 2px solid gray;
   background: #eaeaea;
   margin-top: 5vw;
   margin-bottom: 5vw;
   border-radius: 10px;
   box-shadow: 0px 0px 20px black;
   width: 90%;
   height: 125vw;
   padding-right: 4px;
   padding-left: 4px;
 }
 
 .whole-screen {
   background: black;
   height: 30vw;
   margin: auto;
   margin-top: 2vw;
   width: 90%;
   border-radius: 10px;
   box-shadow: 0px 0px 5px black;
 }
 
 .special-button {
   background: #0c69ff;
   border-radius: 5px;
   margin-top: 5vw;
   margin-left: 2vw;
   color: black;
   font-weight: bold;
   text-align: center;
   box-shadow: 0px 0px 15px gray;
   width: 41vw;
   height: 10vw;
 }
 
 .second-mobile-special {
   margin-left: 0px;
 }
 
 .functions-container {
   display: flex;
   width: 100%;
   margin-left: 0px;
   margin-right: 0px;
   margin-top: 3vw;
 }
 
 .each-function {
   background: #0c69ff;
   border-radius: 5px;
   font-weight: bold;
   text-align: center;
   margin-top: 1vw;
   padding-top: 1vw;
   box-shadow: 0px 0px 15px gray;
   height: 10vw;
   width: 25vw;
   margin-left: 1vw;
   margin-right: 1vw;
 }
 
 .numbers-container {
   display: flex;
   flex-wrap: wrap;
   margin-left: 0px;
   margin-right: 0px;
   width: 100%;
   height: 30%;
 
 }
 
 .each-number {
   background: #0c69ff;
   height: 10vw;
   width: 20vw;
   border-radius: 5px;
   text-align: center;
   margin: auto;
   font-weight: bold;
   box-shadow: 0px 0px 10px gray;
   padding-bottom: 3px;
 }
 
 .screen-for-calc {
   height: 100%;
   width: 98%;
   border-radius: 10px;
   margin-left: .05vw;
   background: black;
   color: yellow;
   font-weight: bold;
   font-size: 20px;
   resize: none;
   padding-left: 1vw;
   padding-right: 1vw;
   padding-top: 1vw;
 }
 
}
 
 
/*  ------calculator------- */
.calc-container {
 width: 463px;
 margin: auto;
 margin-top: 100px;
 padding: 21px;
 border-radius: 10px;
 background: #4b5568;
}
 
.screen {
 font-family: 'Orbitron';
 text-align: right;
 padding: 10px;
 font-size: 45px;
 margin: 5px 8px 10px 8px;
 background: #b2c2b3;
}
 
.log {
 font-family: 'Orbitron';
 background-color: #b2c2b3;
  height: 44px;
 text-align: left;
 outline: none;
 width: 100%;
 border: none;
 font-size: 1em;
}
 
.orange-button {
 background: #995b7a;
}
 
.sm-button {
 color: #fff;
 background: #202742;
 width: 61.1px;
 margin: 14px 8px;
 border-radius: 11px;
 font-size: 1.3em;
 height: 41px;
}
 
.description {
 font-size: 1.5em;
 font-family: 'Orbitron';
 margin: 8px;
 margin-bottom: 12px;
 color: #fff;
}
 
.react-calc{
     width: 97.4%;
       margin-left: 2.5% !important;
}
.button-panel{
         margin-left: 2.5% !important;
}
.s3{
 padding-right: 0;
   padding-left: 0;
}
.s1 {
 padding-right: 0;
 padding-left: 0;
}
 
.react-calc.button-panel.row {
       margin-left: 3.7% !important;
       width: 96.4% !important;
}
.result-panel{
     background: linear-gradient(92deg, black, #ffffff0d) !important;
     /* background-color: black !important; */
     border-top-left-radius: 10px;
       border-top-right-radius: 10px;
}
.button-panel .button{
 box-shadow: inset 1px 1px 0 0 #002f2f !important;
 color: white !important;
 background: linear-gradient(32deg, #1f232d, #283555e3)
}
 
.last{
     background-color: rgb(11 20 41) !important;
}
 
.cur-row{
 color: hsl(177deg 67% 63%) !important;
}
/* .result-panel{
 display: flex;
   flex: 2 1;
   flex-direction: column;
   background: linear-gradient(92deg, black, #ffffff0d);
   padding: 0px 1rem;
   line-height: 1.5rem;
   overflow: scroll;
   border-radius: 10px;
 
 } */
